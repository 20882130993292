import React, { useState } from "react";
import DbService from "../../../Services/LocalDataService";
import "./NewChartForm.css";
import Select from "react-select";
import PositionInput from "../../Inputs/PositionInput";
import { useDispatch } from "react-redux";
import { updateLayout } from "../../../store/slices/monitoringSlice";

const EditedChartForm = (props: any) => {
  const [editedChart, setEditedChart] = useState<any>(props.graph);
  const dispatch = useDispatch();

  let aux: Array<boolean>;
  switch (editedChart.default_visualization) {
    case "line":
      aux = [true, false, false, false];
      break;
    case "speedometer":
      aux = [false, true, false, false];
      break;
    case "pie":
      aux = [false, false, true, false];
      break;
    default:
      aux = [false, false, false, true];
      break;
  }
  const [defaultState, setDefaultState] = useState<Array<boolean>>(aux);

  //y sensor 1 options array
  const [array, setArray] = useState<Array<any>>(
    props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
  );

  const [array2, setArray2] = useState<Array<any>>(
    props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
  );

  const [editingSensors, setEditingSensors] = useState<boolean>(false);

  const onChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    if (name === "xSensor" || name === "zSensor") {
      setEditedChart({ ...editedChart, [name]: parseInt(value) });
    } else {
      setEditedChart({ ...editedChart, [name]: value });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let response;
    if (editingSensors) {
      if (editedChart.ySensors2 === undefined) editedChart.ySensors2 = [];
      if (editedChart.ySensors1 === undefined) {
        alert("erro y 1");
        return;
      }
      let z: null | number;
      if (editedChart.zSensor === -1) {
        z = null;
      } else {
        z = editedChart.zSensor;
      }
      response = await DbService.editGraphSensors(
        editedChart.id,
        editedChart.name,
        editedChart.speedometer_visualization,
        editedChart.line_visualization,
        editedChart.bar_visualization,
        editedChart.pie_visualization,
        editedChart.xSensor,
        editedChart.ySensors1,
        editedChart.ySensors2,
      );
    } else {
      response = await DbService.editChart(editedChart);
    }
    if (response.error) {
      alert(response.error);
    } else {
      props.handleResponse();
      dispatch(updateLayout({original_id: editedChart.id, newLayout: JSON.parse(editedChart.position_metadata)}))
    }
  };

  let options = props.sensors.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));
  const multiSelectChange = (event: any) => {
    if (event.length === 1) {
      const aux = props.sensors.find(
        (element: any) => element.id === event[0].value
      );
      const x = aux.unit;
      filterSensors(x);
    } else if (event.length === 0) {
      setArray(
        props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
      );
    }
    setEditedChart({
      ...editedChart,
      ySensors1: event.map((event: any) => event.value),
    });
  };

  const multiSelectChange2 = (event: any) => {
    if (event.length === 1) {
      const aux = props.sensors.find(
        (element: any) => element.id === event[0].value
      );
      const x = aux.unit;
      filterSensors2(x);
    } else if (event.length === 0) {
      setArray2(
        props.sensors.map((item: any) => ({ value: item.id, label: item.name }))
      );
    }
    setEditedChart({
      ...editedChart,
      ySensors2: event.map((event: any) => event.value),
    });
  };

  const filterSensors = (unit: string) => {
    const filteredoptions = props.sensors.filter((value: any) => {
      return value.unit === unit;
    });
    options = filteredoptions.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    setArray(options);
  };

  const filterSensors2 = (unit: string) => {
    const filteredoptions = props.sensors.filter((value: any) => {
      return value.unit === unit;
    });
    options = filteredoptions.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    setArray2(options);
  };

  const toggleVisualization = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;

    if (
      !(name.replace("Visualization", "") === editedChart.defaultVisualization)
    ) {
      setEditedChart({ ...editedChart, [name]: !editedChart[name] });
    }
  };

  const toggleDefault = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    switch (name) {
      case "line":
        setDefaultState([true, false, false, false]);
        if (editedChart.line_visualization === false) {
          const editedChartCopy = editedChart;
          editedChartCopy.line_visualization = true;
          setEditedChart(editedChartCopy);
        }
        break;
      case "speedometer":
        setDefaultState([false, true, false, false]);
        if (editedChart.speedometer_visualization === false) {
          const editedChartCopy = editedChart;
          editedChartCopy.speedometer_visualization = true;
          setEditedChart(editedChartCopy);
        }
        break;
      case "pie":
        setDefaultState([false, false, true, false]);
        if (editedChart.pie_visualization === false) {
          const editedChartCopy = editedChart;
          editedChartCopy.pie_visualization = true;
          setEditedChart(editedChartCopy);
        }
        break;
      case "bar":
        setDefaultState([false, false, false, true]);
        if (editedChart.bar_visualization === false) {
          const editedChartCopy = editedChart;
          editedChartCopy.bar_visualization = true;
          setEditedChart(editedChartCopy);
        }
        break;
    }
    setEditedChart({ ...editedChart });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-div-input">
        <input
          id="name"
          name="name"
          type="text"
          onChange={onChange}
          placeholder="Digite o nome do Grafico"
          value={editedChart.name}
        />
        <label htmlFor="name">Nome</label>
      </div>
      <PositionInput
        positionString={editedChart.position_metadata}
        onChange={(updatedPosition) =>
          setEditedChart({ ...editedChart, position_metadata: updatedPosition })
        }
      /> 
      <div className="form-div-select">
        <select
          id="time_range"
          name="time_range"
          value={editedChart.time_range}
          onChange={onChange}
          required
        >
          <option className="placeholderOption" disabled selected hidden>
            Selecione o sensor exibido no eixo x
          </option>
          <option value={"minutes"}>Minutos</option>
          <option value={"hours"}>Horas</option>
          <option value={"days"}>Dias</option>
        </select>
        <label htmlFor="time_range">Medida de Tempo</label>
      </div>

      <div className="form-div-input">
        <input
          id="time"
          name="time"
          type="number"
          value={editedChart.time}
          onChange={onChange}
          placeholder="Digite o nome do Grafico"
        />
        <label htmlFor="time">Quantidade de Tempo</label>
      </div>
      <div className="axis">
        {editingSensors ? (
          <div>
            <div className="form-div-select">
              <select id="xSensor" name="xSensor" onChange={onChange} required>
                <option
                  className="placeholderOption"
                  value=""
                  disabled
                  selected
                  hidden
                >
                  Selecione o sensor exibido no eixo x
                </option>
                <option value="time">Tempo</option>
                {props.sensors.map((sensor: any) => {
                  return <option value={sensor.id}>{sensor.name}</option>;
                })}
              </select>
              <label htmlFor="xSensor">Eixo x</label>
            </div>
            <div className="test">
              <Select
                id="ySensors1"
                name="ySensors1"
                options={array}
                isMulti
                onChange={multiSelectChange}
                placeholder="Selecione o(s) sensore(s) exibido(s) no eixo y da esquerda"
              />
              <label htmlFor="ySensors1">Eixo y 1</label>
            </div>
            <div className="test">
              <Select
                id="ySensors2"
                name="ySensors2"
                options={array2}
                isMulti
                onChange={multiSelectChange2}
                placeholder="Selecione o(s) sensore(s) exibido(s) no eixo y da direita"
              />
              <label htmlFor="ySensors2">Eixo y 2</label>
            </div>
            <div className="form-div-select">
              <select id="zSensor" name="zSensor" onChange={onChange} required>
                <option
                  className="placeholderOption"
                  value=""
                  disabled
                  selected
                  hidden
                >
                  Selecione o sensor exibido no eixo z
                </option>
                <option value={-1}>nenhum</option>
                {props.sensors.map((sensor: any) => {
                  return <option value={sensor.id}>{sensor.name}</option>;
                })}
              </select>
              <label htmlFor="zSensor">Eixo z</label>
            </div>
            <p
              className="cancel"
              onClick={() => {
                setEditingSensors(false);
              }}
            >
              cancelar
            </p>
          </div>
        ) : (
          <div className="sensorsList">
            <p>
              Eixo X:{" "}
              {editedChart.x_axis_sensor_id
                ? props.sensors.find(
                    (sensor) => sensor.id === editedChart.x_axis_sensor_id
                  ).name
                : "Tempo"}
            </p>

            <p>Eixo y 1</p>
            <ul>
              {editedChart.y_axis_1.map((sensor) => {
                return <li>{sensor.name}</li>;
              })}
            </ul>
            <p>Eixo y 2</p>
            {editedChart.y_axis_2.length === 0 ? (
              <p>Nenhum</p>
            ) : (
              <ul>
                {editedChart.y_axis_2.map((sensor) => {
                  return <li>{sensor.name}</li>;
                })}
              </ul>
            )}

            <p>
              Eixo Z:{" "}
              {editedChart.z_axis_sensor_id
                ? props.sensors.find(
                    (sensor) => sensor.id === editedChart.z_axis_sensor_id
                  ).name
                : "nenhum"}
            </p>
            <p
              className="toggle"
              onClick={() => {
                setEditingSensors(true);
              }}
            >
              editar sensores
            </p>
          </div>
        )}
      </div>
      <div className="tickBoxes">
        <div className="tickBoxGroup">
          <div>
            <input
              onChange={toggleVisualization}
              checked={editedChart.line_visualization}
              id="line_visualization"
              name="line_visualization"
              type="checkbox"
            />
            <label htmlFor="line_visualization">Linha</label>
          </div>
          <div>
            <input
              id="line"
              name="line"
              type="checkbox"
              checked={defaultState[0]}
              onChange={toggleDefault}
            />
            <label htmlFor="line">Visualização padrão</label>
          </div>
        </div>
        <div className="tickBoxGroup">
          <div>
            <input
              onChange={toggleVisualization}
              checked={editedChart.speedometer_visualization}
              id="speedometer_visualization"
              name="speedometer_visualization"
              type="checkbox"
            />
            <label htmlFor="speedometer_visualization">Velocimetro</label>
          </div>
          <div>
            <input
              id="speedometer"
              name="speedometer"
              type="checkbox"
              checked={defaultState[1]}
              onChange={toggleDefault}
            />
            <label htmlFor="speedometer">Visualização padrão</label>
          </div>
        </div>
        <div className="tickBoxGroup">
          <div>
            <input
              onChange={toggleVisualization}
              checked={editedChart.pie_visualization}
              id="pie_visualization"
              name="pie_visualization"
              type="checkbox"
            />
            <label htmlFor="pie_visualization">Pizza</label>
          </div>
          <div>
            <input
              id="pie"
              name="pie"
              type="checkbox"
              checked={defaultState[2]}
              onChange={toggleDefault}
            />
            <label htmlFor="pie">Visualização padrão</label>
          </div>
        </div>
        <div className="tickBoxGroup">
          <div>
            <input
              onChange={toggleVisualization}
              checked={editedChart.bar_visualization}
              id="bar_visualization"
              name="bar_visualization"
              type="checkbox"
            />
            <label htmlFor="bar_visualization">Barras</label>
          </div>
          <div>
            <input
              id="bar"
              name="bar"
              type="checkbox"
              checked={defaultState[3]}
              onChange={toggleDefault}
            />
            <label htmlFor="bar">Visualização padrão</label>
          </div>
        </div>
      </div>
      <div className="buttons">
        <button
          className="delete"
          onClick={(event) => {
            event.preventDefault();
            props.onClickDelete();
          }}
        >
          deletar
        </button>
        <button className="submmit">Salvar Grafico</button>
      </div>
    </form>
  );
};
export default EditedChartForm;
