import ReactEcharts from "echarts-for-react";
import { useEffect, useMemo, useRef, useState } from "react";
// import { io } from 'socket.io-client';
import { Fullscreen } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import AlertsAlarmsService from "../../Services/AlertsAlarmsService";
import DataService from "../../Services/DataService";
import NewDi2winDataService from "../../Services/NewDi2winDataService";
import { getDigitalTwin } from "../../store/slices/digitalTwinSlice";
import chartRefsManager from "../../Utils/chartRefsManager";

// const socket = io("ws://localhost:3002", {

// });

interface DataItem {
  value: boolean;
  timestamp: string;
}

interface Intervalo {
  start: string;
  end: string;
}

interface Alerta {
  id: number;
  first_alert_time: string;
  last_alert_time: string;
  // outros campos podem ser adicionados conforme necessário
}

const RealTimeMultiSensorChart = ({
  sensorsConfig,
  time,
  timeRange,
  width,
  height,
  chartname,
  chart,
  refIndx,
}: any) => {
  const eChartsRef = useRef<ReactEcharts>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const sensors = useRef<Array<any>>(sensorsConfig);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const theme = useTheme();
  const dbName = useSelector((state: any) => state.db.dbName);
  const collectionName = useSelector(
    (state: any) => state.db.collection
  )
  const digitalTwin = useSelector(getDigitalTwin);
  const [alarmeAreas, setAlarmeArea] = useState<any[]>([]);
  const [status, setStatus] = useState("");
  const [offAreas, setOffAreas] = useState<any[]>([]);
  // const tooltipData = useRef<Array<any>>([]);
  // const [alertsensor, setAlertsensor] = useState<any>()
  const [offsensor, setOffsensor] = useState<any>();
  const kpiSensors = sensors.current.filter(
    (sensor) => sensor.variable_type === "kpi"
  );

  useEffect(() => {
    chartRefsManager.setRef(refIndx, eChartsRef);
  }, [refIndx, eChartsRef]);

  useEffect(() => {
    // chart.alerts_alarms.length > 0 && chart.alerts_alarms.map((element: any) => {
    //   console.log(element)
    //   return setAlertsensor(element)
    // })
    chart.areas.length > 0 &&
      chart.areas.map((element: any) => {
        // console.log(element)
        return setOffsensor(element);
      });
  }, [chart]);

  useEffect(() => {
    // verifica se sensors possui um sensor com variable_type === "kpi"
    const kpiSensor = sensors.current.find(
      (sensor: any) => sensor.variable_type === "kpi"
    );
    kpiSensor !== undefined &&
      digitalTwin !== undefined &&
      AlertsAlarmsService.getAlarmsRange(
        digitalTwin.id,
        moment().subtract(1, "day"),
        moment()
      ).then((data) => {
        // console.log(data)
        const intervalos = criarIntervalosAlert(data);

        setAlarmeArea(intervalos);
      });

    // alertsensor !== undefined && NewDi2winDataService.fetchMonitoringHistoricData(
    //   alertsensor.number,
    //   alertsensor.device_id,
    //   moment(),
    //   moment().subtract(time, timeRange)

    // ).then((data) => {
    //   console.log(data)
    //   // const intervalsOff = criarIntervalosAlert(data);
    //   // console.log(intervalsOff)

    // })
  }, [digitalTwin, time, timeRange, lastUpdate]);

  useEffect(() => {
    offsensor !== undefined &&
      NewDi2winDataService.fetchMonitoringHistoricData(
        dbName,
        collectionName,
        offsensor.number,
        offsensor.device_id,
        time,
        timeRange
      ).then((data) => {
        const intervalos = criarIntervalosOff(data);
        setOffAreas(intervalos);
      });
  }, [offsensor, time, timeRange, lastUpdate, dbName, collectionName]);

  const criarIntervalosAlert = (alertas: Alerta[]): Intervalo[] => {
    // Ordena os alertas por `first_alert_time`
    alertas.sort(
      (a, b) =>
        new Date(a.first_alert_time).getTime() -
        new Date(b.first_alert_time).getTime()
    );

    const intervalos: Intervalo[] = [];

    // Itera sobre os alertas para criar intervalos
    alertas.forEach((alerta) => {
      intervalos.push({
        start: alerta.first_alert_time,
        end: alerta.last_alert_time,
      });
    });

    return intervalos;
  };

  const criarIntervalosOff = (dados: DataItem[]): Intervalo[] => {
    const intervalos: Intervalo[] = [];
    let intervaloAtual: Intervalo | null = null;

    // Verifica se os dados não estão vazios
    if (dados.length === 0) return intervalos;

    dados.reverse().forEach((item) => {
      if (item.value === false) {
        if (!intervaloAtual) {
          // Inicia um novo intervalo
          intervaloAtual = {
            start: item.timestamp,
            end: item.timestamp,
          };
        } else {
          // Atualiza o fim do intervalo atual
          intervaloAtual.end = item.timestamp;
        }
      } else {
        if (intervaloAtual) {
          // Finaliza o intervalo atual e o adiciona à lista
          intervalos.push(intervaloAtual);
          intervaloAtual = null; // Reseta o intervalo atual
        }
      }
    });

    // Verifica se há um intervalo pendente para adicionar
    if (intervaloAtual) {
      intervalos.push(intervaloAtual);
    }

    // Se a lista de intervalos estiver vazia, pode ser que o problema esteja nos dados
    // console.log('Intervalos:', intervalos);

    return intervalos;
  };

  const timePreText = useRef("");
  const timeRangeText = useRef("");

  switch (timeRange) {
    case "minutes":
      timeRangeText.current = "minutos";
      timePreText.current = "Ultimos";
      if (time === 1) {
        timePreText.current = "Ultimo";
        timeRangeText.current = "minuto";
      }
      break;
    case "hours":
      timePreText.current = "Ultimas";
      timeRangeText.current = "horas";
      if (time === 1) {
        timePreText.current = "Ultima";
        timeRangeText.current = "hora";
      }
      break;
    case "days":
      timeRangeText.current = "dias";
      timePreText.current = "Ultimos";
      if (time === 1) {
        timePreText.current = "Ultimo";
        timeRangeText.current = "dia";
      }
      break;
    default:
  }

  // const alarmAreas =useRef<any>(chart && Promise.resolve(getAlarmedChartArea(chart)))
  // const offAreas = useRef<any>(chart && Promise.resolve(getTurnedOffSensorArea(chart)))
  // const tooltip = useRef<any>(chart && Promise.resolve(GetChartTooltips(chart)))

  const createMarkArea = (alarmeAreas, offAreas) => {
    const templateOff = (start, end) => [
      { name: "", xAxis: start, itemStyle: { color: "rgba(0, 0, 0, 0.4)" } },
      { xAxis: end },
    ];

    const templateAlarmed = (start, end) => [
      { name: "", xAxis: start, itemStyle: { color: "rgba(255, 0, 0, 0.3)" } },
      { xAxis: end },
    ];

    // const offIntervals = tooltipData
    //   .filter(dataPoint => !dataPoint.value)
    //   .map(dataPoint => ({
    //     start: dataPoint.start,
    //     end: dataPoint.end
    //   }));

    // const alarmedIntervals = areaData
    //   .filter(dataPoint => dataPoint.value)
    //   .map(dataPoint => ({
    //     start: dataPoint.start,
    //     end: dataPoint.end
    //   }));

    const markAreaData = [
      ...offAreas.map((interval) => templateOff(interval.start, interval.end)),
      ...alarmeAreas.map((interval) =>
        templateAlarmed(interval.start, interval.end)
      ),
    ];

    return { data: markAreaData };
  };

  const COLORS_DARK = useMemo(
    () => ["#F0E442", "#7852E0", "#D55E00", "#009E73", "#fff"],
    []
  );
  const COLORS = useMemo(
    () => [
      "rgb(18, 66, 81)",
      "#98CBFF",
      "#548BBB",
      "#009E73",
      "#000000",
      "#F0E442",
      "#0072B2",
      "#D55E00",
      "#CC79A7",
    ],
    []
  );

  const options = useMemo(
    () => ({
      backgroundColor: "transparent",
      color: theme.palette.mode === "dark" ? COLORS_DARK : COLORS,
      animation: false,

      toolbox: {
        top: 0,
        right: 35,
        show: true,
        feature: {
          saveAsImage: {
            title: "Baixar .png",
            name: chartname,
            pixelRatio: 4,
          },
          dataZoom: {
            title: {
              zoom: "Zoom",
              back: "Voltar",
            },
            yAxisIndex: "all",
            brushStyle: {
              borderWidth: 1,
              color: "rgba(255, 173, 17, 0.4)",
              borderColor: "rgba(255, 173, 17, 1)",
            },
          },
          magicType: {
            title: {
              line: "Linha",
              bar: "Barra",
            },
            type: ["line", "bar"],
          },
          tooltip: {
            show: true,
          },
        },
      },
      title: {
        text: chartname,
        subtext: `${lastUpdate.toLocaleDateString()} - ${lastUpdate.toLocaleTimeString()} (${
          timePreText.current
        } ${time} ${timeRangeText.current})`,
        left: 0,
        top: 0,
        textStyle: {
          fontWeight: 400,
          fontSize: "16px",
          fontFamily: "Roboto",
          color: theme.palette.mode === "dark" ? "white" : "#333",
          wordWrap: "break-word",
        },
        subtextStyle: {
          fontWeight: 400,
          fontSize: 12,
          align: "left",
          fontFamily: "Roboto",
        },
      },
      // tooltip: tooltip.current,
      series: [
        ...sensors.current.map(({ name, data, variable_type }) => ({
          name: `${name}`,
          data: data,
          type: "line",
          showSymbol: false,
          symbol: "circle",
          symbolSize: 9,
          markArea:
            variable_type === "kpi"
              ? createMarkArea(alarmeAreas, offAreas)
              : undefined,
        })),
        ...(kpiSensors.length > 0
          ? [
              {
                name: "Desligado",
                type: "line",

                data: [],
                itemStyle: { color: "rgba(0, 0, 0, 0.4)" },
              },
              {
                name: "Alarmado",
                type: "line",

                data: [],
                itemStyle: { color: "rgba(255, 0, 0, 0.3)" },
              },
            ]
          : []),
      ],

      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
        scale: true,
        max:
          sensors.current.length !== 0 &&
          sensors.current
            .map((sensor) => sensor.sensor_limits_max_value)
            .reduce((a, b) => (a && b ? Math.max(a, b) : null)),
        min:
          sensors.current.length !== 0 &&
          sensors.current
            .map((sensor) => sensor.sensor_limits_min_value)
            .reduce((a, b) => (a && b ? Math.min(a, b) : null)),
        name:
          sensors.current.length !== 0 &&
          sensors.current
            .map((sensor) => sensor.unit.abbreviation)
            .reduce((a, b) => (a && b ? `${a}` : null)),
      },
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          const dateParts = params[0].axisValueLabel.split(" ")[0].split("-");
          const time = params[0].axisValueLabel.split(" ")[1];
          const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
          const unit =
            sensors.current.length !== 0 &&
            sensors.current
              .map((sensor) => sensor.unit.abbreviation)
              .reduce((a, b) => (a && b ? `${a}` : null));

          // const states = params.map(param => {
          //   const isOff = offAreas.some(area => new Date(param.start).getTime() >= area.startTime && new Date(param.end).getTime() <= area.endTime);
          //   const isAlarmed = alarmeAreas.some(area => new Date(param.off).getTime() >= area.startTime && new Date(param.axisValue).getTime() <= area.endTime);
          //   if (isOff) return "Desligado";
          //   if (isAlarmed) return "Alarmado";
          //   return " ";
          // });

          // return `
          //   <div style="display:flex;flex-direction:column;justify-content:center;align-items:start;gap:0px;margin:0px;padding:0px;">
          //     <b style="margin:0px;padding:0px;">${time} ${formattedDate} </b><br/>
          //     ${params.map((param, index) => `
          //       <div style="display:flex;align-items:center;margin:0px;padding:0px;justify-content:space-between;gap:10px">
          //         <span style="display:inline-block;width:10px;height:10px;background-color:${param.color};margin-right:5px;border-radius:50%"></span>
          //         <b style="margin:0px;padding:0px;">${param.seriesName}: </b>
          //         <p style="margin:0px;padding:0px;">${param.data[1]} ${unit} </p>
          //       </div>
          //     `).join('')}
          //     <p style="margin:0px;padding:0px;">
          //      ${'<b style="margin:0px;padding:0px;">Estado:</b> ' + states.join(' ') }
          //       </p>
          //   </div>
          // `;
          return `
        <div style="display:flex;flex-direction:column;justify-content:center;align-items:start;gap:0px;margin:0px;padding:0px;">
          <b style="margin:0px;padding:0px;">${time} ${formattedDate} </b><br/>
          ${params
            .map(
              (param, index) => `
            <div style="display:flex;align-items:center;margin:0px;padding:0px;justify-content:space-between;gap:10px">
              <span style="display:inline-block;width:10px;height:10px;background-color:${param.color};margin-right:5px;border-radius:50%"></span>
              <b style="margin:0px;padding:0px;">${param.seriesName}: </b>
              <p style="margin:0px;padding:0px;">${param.data[1]} ${unit} </p>
            </div>
          `
            )
            .join("")}
          
        </div>
      `;
        },
      },
      dataZoom: [
        {
          type: "inside",
          filterMode: "none",
          minValueSpan: 60 * 1000 * 8,
        },
      ],
      grid: {
        left: 20,
        right: 20,
        top: 80,
        bottom: 30,
        containLabel: true,
      },
      legend: {
        show: true,
        orient: "horizontal",
        bottom: 10,
        selectedMode: true,
        icon: "circle",
        itemGap: 10,
        itemWidth: 10,
        itemHeight: 10,
        padding: [0, 0],
        formatter: (name: string) =>
          `${name.length > 28 ? `${name.slice(0, 10)}...` : name} ${""}`,
        data: [
          ...sensors.current.map((sensor) => sensor.name),
          ...(kpiSensors.length > 0 ? ["Desligado", "Alarmado"] : []),
        ],
      },
    }),
    [
      COLORS,
      COLORS_DARK,
      alarmeAreas,
      chartname,
      kpiSensors.length,
      lastUpdate,
      offAreas,
      theme.palette.mode,
      time,
    ]
  );

  useEffect(() => {
    eChartsRef.current &&
      eChartsRef.current.getEchartsInstance().showLoading("default", {
        text: "Carregando...",
        maskColor: "rgba(255, 255, 255, 0.0)",
        zlevel: 0,
      });

    const getHistoricalData = async () => {
      // const fetchSensorData = async (
      //   sensorNumber,
      //   deviceId,
      //   time,
      //   timeRange
      // ) => {
      //   console.log("fetching data : sensorID = " + sensorNumber +"(tipo: "+ typeof(sensorNumber) + ") deviceID = " + deviceId + " (tipo: "+ typeof(deviceId) + ")" );
      //   const data = await NewDi2winDataService.fetchMonitoringHistoricData(
      //     sensorNumber,
      //     deviceId,
      //     time,
      //     timeRange
      //   );
      //   return data;
      // };
      // const updateChartData = async () => {
      //   const sensorNumberArea = "your-sensor-number-area";
      //   const sensorDeviceIdArea = "your-sensor-device-id-area";
      //   const sensorNumberTooltip = "your-sensor-number-tooltip";
      //   const sensorDeviceIdTooltip = "your-sensor-device-id-tooltip";

      //   const time = "your-time";
      //   const timeRange = "your-time-range";

      //   const areaData = await fetchSensorData(
      //     sensorNumberArea,
      //     sensorDeviceIdArea,
      //     time,
      //     timeRange
      //   );
      //   const tooltipData = await fetchSensorData(
      //     sensorNumberTooltip,
      //     sensorDeviceIdTooltip,
      //     time,
      //     timeRange
      //   );

      //   const series =
      //     sensors.current.length !== 0 &&
      //     sensors.current.map(({ name, data, variable_type }) => ({
      //       name: `${name}`,
      //       data: data,
      //       type: "line",
      //       showSymbol: false,
      //       symbol: "circle",
      //       symbolSize: 9,
      //       markArea:
      //         variable_type === "kpi"
      //           ? createMarkArea(areaData, tooltipData)
      //           : undefined,
      //     }));

      //   return series;
      // };

      // updateChartData().then((updatedSeries) => {
      //   // Use updatedSeries to update your chart
      //   // console.log(updatedSeries);
      // });

      const formatterValue = {
        floatInput: (input: any, n: number): string | void => {
          let dataToreturn = input;
          if (typeof dataToreturn === "string") {
            if (isNaN(parseFloat(dataToreturn))) {
              return dataToreturn as string;
            }
            dataToreturn = parseFloat(dataToreturn).toFixed(n);
            return dataToreturn as string;
          }
          if (typeof dataToreturn === "boolean") {
            if (dataToreturn) {
              return "Ligado" as string;
            } else {
              return "Desligado" as string;
            }
          } else if (typeof dataToreturn === "number") {
            dataToreturn = dataToreturn.toFixed(n);
            return dataToreturn as string;
          }
        },
      };

      // console.log("getHistoricalData")
      for (const sensor of sensors.current) {
        // console.log("fetching data : sensorID = " + sensor.number +" (tipo: "+ typeof(sensor.number) + ") deviceID = " + sensor.device_id + " (tipo: "+ typeof(sensor.device_id) + ")" );
        await NewDi2winDataService.fetchMonitoringHistoricData(
          dbName,
          collectionName,
          sensor.number,
          sensor.device_id,
          time,
          timeRange
        ).then((data) => {
          let lastIndex = -1;
          const processedData: Array<any> = [];

          for (let i = 0; i < data.length; i++) {
            // Ignorando diferenças de timestamp menores que 10s (ajustando a unidade de segundos para '0')
            const adjustedTimestamp = Object.assign(
              [],
              data[i].timestamp.split(""),
              { 18: "0" }
            ).join("");
            lastIndex = data.findLastIndex(
              (element: any) => element.timestamp === data[i].timestamp
            );
            data[lastIndex].timestamp = adjustedTimestamp;
            processedData.push(data[lastIndex]);
            i = lastIndex;
          }

          // console.log(data[data.length - 1])
          sensors.current = sensors.current.map((s) => {
            if (
              s.device_id === sensor.device_id &&
              s.number === sensor.number
            ) {
              const response = processedData.length
                ? processedData.map((d) => ({
                    deviceId: sensor.device_id,
                    sensorId: sensor.number,
                    timestamp: new Date(d.timestamp).toISOString(),
                    value: formatterValue.floatInput(d.value, 2),
                  }))
                : [];
              setLastUpdate(new Date());
              return {
                ...s,
                data: response.map((d) => [d.timestamp, d.value]),
              };
            }
            return s;
          });
        });
      }

      eChartsRef.current &&
        eChartsRef.current.getEchartsInstance().setOption({
          series: sensors.current.map(({ name, data }) => ({
            name: `${name}`,
            data: data,
          })),
        });
    };

    getHistoricalData();

    const interval = setInterval(() => {
      getHistoricalData();
    }, 30000);

    return () => clearInterval(interval);
  }, [collectionName, dbName, time, timeRange]);

  // useEffect(() => {

  //   socket.on("connect", () => {
  //     socket.emit("sensor_info", sensors.current);
  //     // console.log("Conectado ao Socket");
  //   });

  //   socket.on("disconnect", () => {
  //     // console.log("Desconectado do servidor Socket");
  //   });

  //   // console.log("sensors",sensors);
  // }, [ sensors ]);

  // useEffect(() => {
  //   // chart.areas.length > 0 && console.log("sensor de areas desligadas",chart.areas);
  // }, [chart]);

  const toggleFullscreen = () => {
    if (chartContainerRef.current) {
      if (!isFullscreen) {
        chartContainerRef.current.requestFullscreen().catch((err) => {
          // console.error(`Failed to enable fullscreen mode: ${err.message} (${err.name})`);
        });
      } else {
        document.exitFullscreen().catch((err) => {
          // console.error(`Failed to exit fullscreen mode: ${err.message} (${err.name})`);
        });
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  useEffect(() => {
    DataService.getTwins().then((res1) => {
      const response = res1.filter((twin) => twin.id === digitalTwin.id).at(-1);

      response && setStatus(response.status);
    });
  }, [digitalTwin, lastUpdate]);

  return (
    <div
      ref={chartContainerRef}
      onDoubleClick={toggleFullscreen}
      style={{
        position: "relative",
        height: height,
        width: width,
        top: "-50px",
        border:
          kpiSensors.length > 0 && status === "alarm"
            ? "3px solid red"
            : "none",
        backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#fff",
      }}
    >
      <div
        title="Fullscreen"
        style={{
          position: "absolute",
          top: "12px",
          right: "10px",
          color: theme.palette.mode === "dark" ? "#fff" : "#000",
          cursor: "pointer",
          zIndex: 1,
        }}
      >
        <Fullscreen onClick={toggleFullscreen} />
      </div>
      <ReactEcharts
        ref={eChartsRef}
        option={options}
        theme={theme.palette.mode === "dark" ? "dark" : "light"}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "10px",
          zIndex: 0,
        }}
      />
    </div>
  );
};

export default RealTimeMultiSensorChart;
