import { Box, Card, CardContent, Paper, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TwinCard from "../../Components/Cards/TwinCard/TwinCard";

import { getUser } from "../../store/slices/userSlice";


import { getCompany, setCompany } from "../../store/slices/companyslice";
import LocalDataService from "../../Services/LocalDataService";

const PainelTwins = () => {

  
  const user = useSelector(getUser);
  const company = useSelector(getCompany);
 
  const [digitalTwinList, setDigitalTwinList] = useState([]);

 


  useEffect(() => {
    // console.log(company)
    if(user.companies.length === 0) {
      LocalDataService.get_digitalTwins(company.id).then((data) => {
        // console.log(data.sort((a, b) => a.id - b.id));
        setDigitalTwinList(data);
      })
      
    } 
  }, [user.companies.length, company.id, company]);





  const theme = useTheme();
 

 const dispatch = useDispatch<any>();
 
  return (
    <Box sx={{ display: "flex", flexDirection: "row",flexWrap: "wrap", gap: "20px", padding: "10px" }}>
      {user.companies.length === 0 &&
      <Card key={company.id} sx={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center",justifyContent: "center" }}>
      <div><Typography variant="h5">{company.name}</Typography></div><img src={company.complement} alt={company.name} style={{ width: "50px", height: "50px", marginLeft: "10px", objectFit: "contain" }}  />
        
        </div>
      <CardContent sx={{ display: "flex", flexDirection: "row", gap: "10px",flexWrap: "wrap", }}>
      {digitalTwinList.map((digitalTwin: any) => (
        <TwinCard key={digitalTwin.id + 'informa'} digitalTwin={digitalTwin} />

      ))}

      {digitalTwinList.length === 0 && (
                 <Paper
                 elevation={0}
                
                 key={'notTwins'}
                 sx={{
                   width: "300px",
                   height: "300px",
                   cursor: "not-allowed",
                   boxSizing: "border-box",
                   display: "flex",
                   flexDirection: "column",
                   justifyContent: "center",
                   alignItems: "center",
                   border: "1px solid #E0E0E0",
                   transition: "opacity 0.5s ease-in-out",
                   
                   
                   
                 }}
               >
                 <Box
                   style={{
                     display: "flex",
                     flexDirection: "row",
                     justifyContent: "space-between",
                     alignItems: "center",
                     
                     
                     margin: "0",
                     fontFamily: "Roboto",
                     fontWeight: "bold",
                   }}
                 >
                   <Typography
                     sx={{
                       fontFamily: "Roboto",
                       fontWeight: 500,
                       fontSize: "18px",
                       cursor: "pointer",
                       color:theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
                     }}
                   >
                     {'Sem Gemeos'}
                   </Typography>
                   <Box
                     style={{
                       padding: "0px",
                       fontFamily: "Roboto",
                       fontWeight: 500,
                       fontSize: "18px",
                       cursor: "pointer",
                     }}
                   >
                     
                   </Box>
                 </Box>
           
                 
                 <Box
                       sx={{
                         
                         
                         display: "flex",
                         flexDirection: "column",
                         
                         
                         fontFamily: "Roboto",
                         fontWeight: 400,
                         fontSize: "14px",
                         cursor: "pointer",
                       }}
                     >
                      
           
                       <Box
                         sx={{
                           display: "flex",
                           flexDirection: "column",
                           justifyContent: "center",
                           alignItems: "center",
                           fontFamily: "Roboto",
                           fontWeight: 400,
                           cursor: "pointer",
                           fontSize: "14px",
                           color:theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
                         }}
                       >
                         
                       </Box>
                 </Box>
                  
               </Paper>
            )}
      

      </CardContent>
    </Card>
      }
      
      {user.companies.map((company) => (
        <Card key={company.id} sx={{ margin: "0px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center",justifyContent: "center" }}>
            <div><Typography variant="h5">{company.name}</Typography></div> <img src={company.group.logo} alt={company.name} style={{ width: "50px", height: "50px", marginLeft: "10px" , objectFit: "contain" }}/>
            </div>
          <CardContent sx={{ display: "flex", flexDirection: "row", gap: "10px",flexWrap: "wrap"}} onClick={() => {
                  dispatch(
                    setCompany({ id: company.id, name: company.name, complement: company.group.logo })
                  );
                }}>
                  
            {company.digitalTwins.map((digitalTwin) => (
                <TwinCard key={digitalTwin.id} digitalTwin={digitalTwin}  />
              ))}
            {company.digitalTwins.length === 0 && (
                 <Paper
                 elevation={0}
                
                 key={'notTwins'}
                 sx={{
                   width: "300px",
                   height: "300px",
                   cursor: "not-allowed",
                   boxSizing: "border-box",
                   display: "flex",
                   flexDirection: "column",
                   justifyContent: "center",
                   alignItems: "center",
                   border: "1px solid #E0E0E0",
                   transition: "opacity 0.5s ease-in-out",
                   
                   
                   
                 }}
               >
                 <Box
                   style={{
                     display: "flex",
                     flexDirection: "row",
                     justifyContent: "space-between",
                     alignItems: "center",
                     
                     
                     margin: "0",
                     fontFamily: "Roboto",
                     fontWeight: "bold",
                   }}
                 >
                   <Typography
                     sx={{
                       fontFamily: "Roboto",
                       fontWeight: 500,
                       fontSize: "18px",
                       cursor: "pointer",
                       color:theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
                     }}
                   >
                     {'Sem Gemeos'}
                   </Typography>
                   <Box
                     style={{
                       padding: "0px",
                       fontFamily: "Roboto",
                       fontWeight: 500,
                       fontSize: "18px",
                       cursor: "pointer",
                     }}
                   >
                     
                   </Box>
                 </Box>
           
                 
                 <Box
                       sx={{
                         
                         
                         display: "flex",
                         flexDirection: "column",
                         
                         
                         fontFamily: "Roboto",
                         fontWeight: 400,
                         fontSize: "14px",
                         cursor: "pointer",
                       }}
                     >
                      
           
                       <Box
                         sx={{
                           display: "flex",
                           flexDirection: "column",
                           justifyContent: "center",
                           alignItems: "center",
                           fontFamily: "Roboto",
                           fontWeight: 400,
                           cursor: "pointer",
                           fontSize: "14px",
                           color:theme.palette.mode === "dark" ? "#FFFFFF" : "#4D4D4D",
                         }}
                       >
                         
                       </Box>
                 </Box>
                  
               </Paper>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default PainelTwins;
