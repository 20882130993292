import { SyntheticEvent, useEffect, useState } from "react";
import { ReactComponent as Icon } from "../../Assets/Icons/magnifier.svg";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import DbService from "../../Services/LocalDataService";
import { useParams } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DataService from "../../Services/DataService";


import {
  InputAdornment,
  OutlinedInput,
  Paper,
  Typography,
  Button,
  Box,
  useTheme,
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Tab,
  Tabs,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  // SelectChangeEvent,
  Switch,
  FormControlLabel,
  
  Stack,
  Collapse,
  FormControl,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { getUser } from "../../store/slices/userSlice";

import { getCompanies, setCompaniess } from "../../store/slices/companiesSlice";
import { Add, Delete, Edit, Sensors } from "@mui/icons-material";

interface IGroup {
  pontoFocal: string;
  pais: string;
  cep: string;
  estado: string;
  bairro: string;
  cidade: string;
  logradouro: string;
  telefone: string;
  email: string;
  id: number;
  name: string;
  cnpj: string;
  address: string;
  number: string;
  logo: string;
  neighborhood: string;
  zipcode: string;
  date: string;
  companies: Array<ICompany>;
  

}

interface ICompany {
  id: number;
  name: string;
  inclusion_date: string;
  cnpj: string;
  updatedAt: string;
  users: Array<IUsers>;
  digitalTwins: Array<IDigitalTwin>;
  complement: string;
  neighborhood: string;
  zipcode: string;
  address: string;
  number: string;
  

}

interface IUsers {
  id: number;
  name: string;
  email: string;
  password: string;
  permition_level: string;
  company_id: number;
  users_companies: any;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
  email_confirmation_code: string;

}

interface IDigitalTwin {
  id: number;
  name: string;
  assets: any;
  inclusion_date: string;
  createdAt: string;
  updatedAt: string;
  parent_id: number;
}

const SetupGroup = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [valuetab, setValuetab] = useState('1');

  const [permitionLevel, setPermitionLevel] = useState('');
  const [companie, setCompanie] = useState('');
  const [companieTwin, setCompanieTwin] = useState('');
  const [addingCompany, setAddingCompany] = useState<boolean>(false);

  const [addingUser, setAddingUser] = useState<boolean>(false);
  const [group, setGroup] = useState<IGroup | null>(null);
  
  const [copyFromGroup, setCopyFromGroup] = useState(false);
  
  // variáveis relacionadas à adição de gêmeos digitais
  const [addingTwin, setAddingTwin] = useState<boolean>(false);
  const [newTwinName, setNewTwinName] = useState<any>('');
  const [ativeValue, setAtiveValue] = useState('');
  const [newTwinIn, setNewTwinIn] = useState<any>('');
  const [newTwinOut, setNewTwinOut] = useState<any>('');

  // valores relacionados à edição de gêmeos digitais
  const [updateTwinInfo, setUpdateTwinInfo] = useState<boolean>(false);
  const [updatedTwinId, setUpdatedTwinId] = useState<any>(null);

  // valores relacionadas à adição de variáveis
  const [addingVariable, setAddingVariable] = useState<boolean>(false);
  const [newVariableName, setNewVariableName] = useState<any>(null);
  const [newVariableUnit, setNewVariableUnit] = useState<any>(null);
  const [newVariableGranularity, setNewVariableGranularity] = useState<any>(null);
  const [newTypeOfVariable, setNewTypeOfVariable] = useState<any>(null);
  const [newVariableTypeSensor, setNewVariableTypeSensor] = useState<any>(null);
  const [newVariableSensor, setNewVariableSensor] = useState<any>(null);
  const [newVariableDevice, setNewVariableDevice] = useState<any>(null);
  const [newVariableInfEngLimit, setNewVariableInfEngLimit] = useState<any>(null);
  const [newVariableSupEngLimit, setNewVariableSupEngLimit] = useState<any>(null);
  const [newVariableType, setNewVariableType] = useState<any>(null);
  const [newVariableInfLimit, setNewVariableInfLimit] = useState<any>(null);
  const [newVariableSupLimit, setNewVariableSupLimit] = useState<any>(null);
  const [newVariableReadAmount, setNewVariableReadAmount] = useState<any>(null);
  const [newVariableTime, setNewVariableTime] = useState<any>(null);
  const [newVariableTwin, setNewVariableTwin] = useState<any>(null);
  const [newVariableSensorsList, setNewVariableSensorsList] = useState<any>([]);
  const [units, setUnits] = useState<any>([]);

  // valores relacionados à edição de variáveis
  const [editVariableInfo, setEditVariableInfo] = useState<boolean>(false);
  const [newVariableId, setNewVariableId] = useState<any>(null);

  // valores relacionados à remoção de variáveis
  const [openConfirmDialogSensor, setOpenConfirmDialogSensor] = useState(false);
  const [deletedVariableName, setDeletedVariableName] = useState<any>(null);
  const [deletedVariableId, setDeletedVariableId] = useState<any>(null);

  // valores relacionadas à edição de empresas
  const [updateCompanyInfo, setUpdateCompanyInfo] = useState<boolean>(false);
  
  // const [parentDts, setParentsDts] = useState<IDigitalTwin[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [newUserTelefone, setNewUserTelefone] = useState<string>('');
  const [newUserEmail, setNewUserEmail] = useState<string>('');
  const [editUserInfo, setEditUserInfo] = useState<boolean>(false);
  
  const [isEditing, setIsEditing] = useState(false);
  const [newGroupName, setNewGroupName] = useState<any>();
  const [newCnpj, setNewCnpj] = useState<any>();
  const [newLogradouro, setNewLogradouro] = useState<any>();
  const [newBairro, setNewBairro] = useState<any>();
  const [newCidade, setNewCidade] = useState<any>();
  const [newEstado, setNewEstado] = useState<any>();
  const [newCep, setNewCep] = useState<any>();
  const [newEmail, setNewEmail] = useState<any>();
  const [newTelefone, setNewTelefone] = useState<any>();
  
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openConfirmDialogUser, setOpenConfirmDialogUser] = useState<boolean>(false);
  
  const [anchorLogo, setAnchorLogo] = useState<null | HTMLElement>(null);
  const [openLogo, setOpenLogo] = useState(false);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState<any>(null);
  const [selectedLogo, setSelectedLogo] = useState<any>(null);
  const [listedCompanies, setListedCompanies] = useState<any[]>([]);
  const [dashboardList, setDashboardList] = useState<any[]>([]);
  
  const [usersList, setUsersList] = useState<any[]>([]);
  const [digitalTwinsList, setDigitalTwinsList] = useState<any[]>([]);
  
  const [newCompanyName, setNewCompanyName] = useState<any>('');
  const [cnpj, setCnpj] = useState<any>('');
  const [newCompanyPontoFocal, setNewCompanyPontoFocal] = useState<any>('');
  const [newCompanyEmail, setNewCompanyEmail] = useState<any>('');
  const [newCompanyTelefone, setNewCompanyTelefone] = useState<any>('');

  const [newCompanyCep, setNewCompanyCep ] = useState<any>('');
  const [newCompanyPais, setNewCompanyPais ] = useState<any>('');
  const [newCompanyEstado, setNewCompanyEstado ] = useState<any>('');
  const [newCompanyCidade, setNewCompanyCidade  ] = useState<any>('');
  const [newCompanyBairro, setNewCompanyBairro ] = useState<any>('');
  const [newCompanyLogradouro,  setNewCompanyLogradouro ] = useState<any>('');

  // Valores relacionados a adição de gráficos
  const [newCompanyGraph, setNewCompanyGraph] = useState<any>(null);
  const [newDtwinGraph, setNewDtwinGraph] = useState<any>(null);
  const [newDtwinId, setNewDtwinId] = useState<any>(null);
  const [addingGraph, setAddingGraph] = useState<boolean>(false);
  const [newGraphName, setNewGraphName] = useState('');
  const [position, setPosition] = useState('');
  const [time1, setTime1] = useState('');
  const [time2, setTime2] = useState<number | null>(null);
  const [isXTime, setIsXTime] = useState(false);
  const [xAxisSensorId, setXAxisSensorId] = useState<any>(null);
  const [yAxis1, setYAxis1] = useState<any>(null);
  const [yAxis2, setYAxis2] = useState<any>(null);
  const [zAxis, setZAxis] = useState<any>(null);
  const [lineVisualization, setLineVisualization] = useState(false);
  const [pieVisualization, setPieVisualization] = useState(false);
  const [barVisualization, setBarVisualization] = useState(false);
  const [speedometerVisualization, setSpeedometerVisualization] = useState(false);
  const [speedometerSensor, setSpeedometerSensor] = useState<any>(null);
  const [speedometerBreak1, setSpeedometerBreak1] = useState<any>(null);
  const [speedometerBreak2, setSpeedometerBreak2] = useState<any>(null);
  const [speedometerMin, setSpeedometerMin] = useState<any>(null);
  const [speedometerMax, setSpeedometerMax] = useState<any>(null);
  const [timeRange, setTimeRange] = useState<any>(null);
  const [timeUnits, setTimeUnits] = useState<any>(null);
  const [alertsAlarms, setAlertsAlarms] = useState<Array<number> | null>([]);
  const [userId, setUserId ] = useState<any>(null);
  const [newGraphSensorsList, setNewGraphSensorsList] = useState<any>([]);
  const [defaultVisualization, setDefaultVisualization] = useState<any>('');

  // valores relacionados a edição de gráficos
  const [editGraph, setEditGraph] = useState<boolean>(false);
  const [editGraphId, setEditGraphId] = useState<any>(null);

  // valores relacionados a remoção de gráficos
  const [openConfirmDialogGraph, setOpenConfirmDialogGraph] = useState<boolean>(false);
  const [deletedGraphName, setDeletedGraphName] = useState<any>(null);
  const [deletedGraphId, setDeletedGraphId] = useState<any>(null);

  const [newUserName, setNewUserName] = useState<any>('');
  const companiess = useSelector(getCompanies);
  const user = useSelector(getUser);
  const [currentCompany, setCurrentCompany]= useState<any>(0);

  const getGraphInfo = async (id: any) => {
    const graph = dashboardList.find((graph) => graph.id === id);
    
    if (graph.time) {
      setIsXTime(true);
    }

    setEditGraphId(id);
    setNewDtwinId(graph.digital_twin_id);
    setNewGraphName(graph.name);
    setXAxisSensorId(graph.x_axis_sensor_id);
    setDefaultVisualization(graph.default_visualization);
    setLineVisualization(graph.line_visualization);
    setPieVisualization(graph.pie_visualization);
    setBarVisualization(graph.bar_visualization);
    setSpeedometerVisualization(graph.speedometer_visualization);
    setSpeedometerSensor(graph.speedometer_main_sensor_id);
    setSpeedometerMin(graph.speedometer_min);
    setSpeedometerMax(graph.speedometer_max);
    setSpeedometerBreak1(graph.speedometer_break_1);
    setSpeedometerBreak2(graph.speedometer_break_2);
    setTimeRange(graph.time_range);
    setTimeUnits(graph.time);

    if (graph.y_axis_1[0]) {
      setYAxis1(graph.y_axis_1[0].id);
    }

    if (graph.y_axis_2[0]) {
      setYAxis2(graph.y_axis_2[0].id);
    }

    setNewDtwinGraph(digitalTwinsList.find(twin => twin.id === newDtwinId));
  }

  const deleteDashboard = async (id: any) => {
    try {
      await DbService.deleteGraph(id);
      setDashboardList(dashboardList.filter((graph) => graph.id !== id));

    } catch (error) {
      alert(error);
    }
  }

  const saveGraph = async () => {
    try {
      setAddingGraph(false);

      await DbService.store_graph(
        newDtwinId,
        newGraphName,
        speedometerVisualization,
        lineVisualization,
        barVisualization,
        pieVisualization,
        xAxisSensorId,
        defaultVisualization,
        yAxis1,
        yAxis2,
        speedometerMax,
        speedometerMin,
        speedometerBreak1,
        speedometerBreak2,
        speedometerSensor,
        timeRange,
        timeUnits,
      );

      setNewCompanyGraph(null);
      setNewDtwinGraph(null);
      setNewDtwinId(null);
      setNewGraphName('');
      setXAxisSensorId(null);
      setYAxis2(null);
      setYAxis1(null);
      setLineVisualization(false);
      setPieVisualization(false);
      setBarVisualization(false);
      setSpeedometerVisualization(false);
    } catch (error) { 
      alert(error);
    }
  }

  const handleUpdateGraph = async (id: any) => {
    try {
      setEditGraph(false);

      await DbService.editGraphSensors(
        id,
        newGraphName,
        speedometerVisualization,
        lineVisualization,
        barVisualization,
        pieVisualization,
        xAxisSensorId,
        defaultVisualization,
        yAxis1,
        yAxis2,
        speedometerMax,
        speedometerMin,
        speedometerBreak1,
        speedometerBreak2,
        speedometerSensor,
        timeRange,
        timeUnits,
      );

      setAddingGraph(false);
      setNewCompanyGraph(null);
      setNewDtwinGraph(null);
      setNewDtwinId(null);
      setEditGraph(false);
      setNewGraphName('');
      setPosition('');
      setTime1('');
      setTime2(null);
      setIsXTime(false);
      setXAxisSensorId(null);
      setYAxis1(null);
      setYAxis2(null);
      setDefaultVisualization(null);
      setLineVisualization(false);
      setPieVisualization(false);
      setBarVisualization(false);
      setSpeedometerVisualization(false);
      setSpeedometerSensor(null);
      setSpeedometerMin(null);
      setSpeedometerMax(null);
      setSpeedometerBreak1(null);
      setSpeedometerBreak2(null);
      setTimeRange(null);
      setTimeUnits(null);
      setAlertsAlarms([]);
    } catch (error) { 
      alert(error);
    }
  }

  const getUserInfo = async (id: any) => {
    const user = usersList.find((user) => user.id === id);
    
    setUserId(id);
    setNewUserName(user.name);
    setNewUserEmail(user.email);
    setPermitionLevel(user.permition_level);
    setCompanie(user.companies[0].id);
    setNewUserTelefone(user.telefone);
  };

  const getTwinInfo = async (id: any) => {
    setUpdatedTwinId(id);

    try {
      const res = await DbService.get_digitalTwin(id);

      setNewTwinName(res.name);
      setNewTwinIn(res.input_utility);
      setNewTwinOut(res.output_utility);
      setCompanieTwin(res.company_id);
      setAtiveValue(res.ative);

    } catch (error) {
      alert(error);
    }
  }

  const handleUpdateUser = async () => {
    try {
      await DbService.edit_user(
        userId,
        newUserName,
        newUserEmail,
        permitionLevel,
        newUserTelefone,
        parseInt(companie)
      );

      const newUserslist = await DbService.get_users_from_group(companie.toString());
      
      setUsersList(newUserslist);
      setEditUserInfo(false);

      setNewUserName('');
      setNewUserEmail('');
      setPermitionLevel('');
      setCompanie('');
      setNewUserTelefone('');
    } catch (error) {
      alert(error);
    }
  }

  const handleUpdateVariable = async (id: any) => {
    try {
      await DbService.editSensor(
        id,
        newVariableName,
        newVariableUnit,
        newVariableGranularity,
        newTypeOfVariable,
        newVariableTypeSensor,
        newVariableSensor,
        newVariableDevice,
        newVariableInfEngLimit,
        newVariableSupLimit,
        newVariableType,
        newVariableInfLimit,
        newVariableSupLimit,
        newVariableReadAmount,
        newVariableTime,
      );

      setEditVariableInfo(false);
      setNewVariableName(null);
      setNewVariableUnit(null);
      setNewVariableGranularity(null);
      setNewTypeOfVariable(null);
      setNewVariableTypeSensor(null);
      setNewVariableSensor(null);
      setNewVariableInfEngLimit(null);
      setNewVariableSupEngLimit(null);
      setNewVariableType(null);
      setNewVariableInfLimit(null);
      setNewVariableSupLimit(null);
      setNewVariableReadAmount(null);
      setNewVariableTime(null);

    } catch (error) {
      alert(error);
    }
  }

  const handleUpdateTwin = async (id: number) => {
    try {
      await DbService.edit_dt(
        id,
        newTwinName,
        newTwinIn,
        newTwinOut,
        companieTwin as any,
        ativeValue as any,
        null,
      );

      const newDigitalTwinsList = await DbService.get_digitalTwinsbyGroup(id as any);

      const ids = companies.map(company => company.id)
      setDigitalTwinsList(newDigitalTwinsList.filter((dt) => ids.includes(dt.company_id)));
      setUpdateTwinInfo(false);
      setNewTwinName('');
      setCompanieTwin('');
      setAtiveValue('');
      setNewTwinIn('');
      setNewTwinOut('');

    } catch (error) {
      alert(error);
    }
  }

  const getCompanyInfo = async (id: string) => {
    try {
      const res = await DbService.getCompany(id);
      
      setNewCompanyName(res.name);
      setNewCompanyEmail(res.email);
      setNewCompanyCep(res.zipcode);
      setNewCompanyPais(res.country);
      setNewCompanyEstado(res.state);
      setNewCompanyCidade(res.city);
      setNewCompanyBairro(res.neighborhood);
      setNewCompanyLogradouro(res.address);
      setNewCompanyPontoFocal(res.ponto_focal);
      setNewCompanyTelefone(res.telefone);
      setCnpj(res.cnpj);
    } catch (error) {
      alert(error);
    }
  };

  const handleSaveChanges = async () => {
    if (group) {
      // Cria um objeto atualizado com os novos valores
      const updatedGroup = {
        ...group,
        name: newGroupName || group.name,
        cnpj: newCnpj || group.cnpj,
        logradouro: newLogradouro || group.logradouro,
        bairro: newBairro || group.bairro,
        cidade: newCidade || group.cidade,
        estado: newEstado || group.estado,
        cep: newCep || group.cep,
        email: newEmail || group.email,
        telefone: newTelefone || group.telefone,
      };
  
      try {
        // Chama o serviço para editar o grupo
        const response = await DbService.editGroup(updatedGroup);
  
        // Atualiza o estado com o grupo retornado da resposta
        setGroup(response);
  
        // Sai do modo de edição
        setIsEditing(false);
      } catch (error) {
        console.error("Erro ao atualizar o grupo:", error);
        // Aqui você pode adicionar algum feedback de erro para o usuário, se necessário.
      }
    }
  };
 
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredcompanies = companies.filter((company) => {
      if (company.name.toLowerCase().includes(value.toLowerCase())) {
        return company;
      } else {
        return null;
      }
    });
    setListedCompanies(filteredcompanies);
  };

  const handleSearchUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredusers = usersList.filter((user) => {
      if (user.name.toLowerCase().includes(value.toLowerCase())) {
        return user;
      } else {
        return null;
      }
    });
    value === "" ? setUsersList(usersList) : setUsersList(filteredusers);
    
  };


  const handleOpenLogoMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLogo(event.currentTarget as HTMLElement);
    setOpenLogo(true);
  }

  const handleLogoChange = async(e) => {
    const file = e.target.files[0];
    setSelectedLogo(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  }

  const handleCloseLogoMenu = () => {
    setAnchorLogo(null);
    setOpenLogo(false);
  }
  const handleCancelLogo = () => {
    setLogoPreviewUrl(user.logo ? user.logo : null);
    setSelectedLogo(null);
    handleCloseLogoMenu();
  };
  
  const handleSaveLogo = async () => {
    const groupModified = {...group, logo: logoPreviewUrl};
    const updatedGroup = await DbService.editGroup(groupModified)

    setGroup(updatedGroup);
    setSelectedLogo(null);
    handleCloseLogoMenu();
  
    // Atualiza o complement da empresa selecionada
    const updatedCompanies = companiess.map(company => {
      if (company.id === group?.id) { // Supondo que group?.id seja o id da empresa selecionada
        return {
          ...group,
          complement: selectedLogo, // Substitua selectedLogo pelo valor desejado
        };
      }
      return company; // Retorna a empresa sem alterações se não for a selecionada
    });
    // Dispara a ação para atualizar o estado das empresas
    dispatch(setCompaniess(updatedCompanies));
  
    return; // Garante que a função retorne algo (nesse caso, undefined)
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };
  const handleConfirmDelete = () => {
    setOpenConfirmDialog(true);
  };


  const handleConfirmDeleteUser = ( id) => {
    setOpenConfirmDialogUser(true);
  };

  const getUnits = async() => {
    const res = await DbService.get_units();
    setUnits(res);
  };
  
  const handleAddUser = async() => {
    
    try {
      await DbService.store_user(
        newUserName,
        newUserEmail,
        permitionLevel,
        companie as any,
        newUserTelefone,
    );

      const newUserslist = await DbService.get_users_from_company(companie as any);
      
      setUsersList(newUserslist);
      setAddingUser(false);

      setNewUserName('');
      setNewUserEmail('');
      setPermitionLevel('');
      setCompanie('');
      setNewUserTelefone('');
      
    } catch (error) {
      alert(error);
    }
    
  }

  const handleDeleteCompany = async (id) => {
    try {
      if (id !== null) {
        await DbService.deleteCompany(id);
        setCompanies(companies.filter((company) => company.id !== id));
        setListedCompanies(companies.filter((company) => company.id !== id));
    
        
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleDeleteUser = async (id) => {
    
    try {
      if (id !== null) {
        await DbService.deleteUser(id);
        setUsersList(usersList.filter((user) => user.id !== id));
    
        
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleDeleteVariable = async(id) => {
    try {
      if (id !== null) {
        await DbService.deleteSensor(id);        
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValuetab(newValue);
  };

  const handleAddTwin = async() => {
    
    try {
      await DbService.store_dt(
        newTwinName,
        newTwinIn,
        newTwinOut,
        companieTwin as any,
        ativeValue as any,
      );

      const newDigitalTwinsList = await DbService.get_digitalTwinsbyGroup(id as any);
      const ids = companies.map(company => company.id)
      setDigitalTwinsList(newDigitalTwinsList.filter((dt) => ids.includes(dt.company_id)));
      setAddingTwin(false);
      setNewTwinName('');
      setCompanieTwin('');
      setAtiveValue('');
      setNewTwinIn('');
      setNewTwinOut('');

    } catch (error) {
      alert(error);
    }
  }

  const handleAddVariable = async() => {
    try {
      await DbService.store_sensor(
        newVariableTwin.id,
        newVariableName,
        newVariableUnit,
        newVariableGranularity,
        newTypeOfVariable,
        newVariableTypeSensor,
        newVariableSensor,
        newVariableDevice,
        newVariableInfEngLimit,
        newVariableSupEngLimit,
        newVariableType,
        newVariableInfLimit,
        newVariableSupLimit,
        newVariableReadAmount,
        newVariableTime,
      );

      setAddingVariable(false);
      setNewVariableName(null);
      setNewVariableUnit(null);
      setNewVariableGranularity(null);
      setNewTypeOfVariable(null);
      setNewVariableTypeSensor(null);
      setNewVariableSensor(null);
      setNewVariableInfEngLimit(null);
      setNewVariableSupEngLimit(null);
      setNewVariableType(null);
      setNewVariableInfLimit(null);
      setNewVariableSupLimit(null);
      setNewVariableReadAmount(null);
      setNewVariableTime(null);
    } catch (error) {
      alert(error);
    }
  }

  const theme = useTheme();

  const handleAddCompany = async() => {
    try {
      await DbService.createCompany({
        groupId: id,
        newCompanyName,
        cnpj,
        newCompanyEmail,
        newCompanyPontoFocal,
        newCompanyTelefone,
        newCompanyLogradouro,
        newCompanyBairro,
        newCompanyCep,
        newCompanyPais,
        newCompanyCidade,
        newCompanyEstado,
      });

      const newCompanyList = await DbService.get_group(id as any);
      
      setListedCompanies(newCompanyList.companies);
      setAddingCompany(false);
      setNewCompanyName('');
      setNewCompanyEmail('');
      setNewCompanyCep('');
      setNewCompanyPais('');
      setNewCompanyEstado('');
      setNewCompanyCidade('');
      setNewCompanyBairro('');
      setNewCompanyLogradouro('');
      setNewCompanyPontoFocal('');
      setNewCompanyTelefone('');
      setCnpj('');
    } catch (error) {
      alert(error);
    } 
  }

  const handleUpdateCompany = async (companyId) => {
    try {
      await DbService.update_company(
        companyId,
        newCompanyName,
        cnpj,
        newCompanyLogradouro,
        newCompanyTelefone,
        newCompanyPontoFocal,
        newCompanyBairro,
        newCompanyCep,
        newCompanyPais,
        newCompanyEstado,
        newCompanyCidade,
        newCompanyEmail,
        Date.now()
      );

      const newCompanyList = await DbService.get_group(id as any);
      
      setListedCompanies(newCompanyList.companies);
      setUpdateCompanyInfo(false);
      setNewCompanyName('');
      setNewCompanyEmail('');
      setNewCompanyCep('');
      setNewCompanyPais('');
      setNewCompanyEstado('');
      setNewCompanyCidade('');
      setNewCompanyBairro('');
      setNewCompanyLogradouro('');
      setNewCompanyPontoFocal('');
      setNewCompanyTelefone('');
      setCnpj('');

    } catch (error) {
      alert(error);
    }
  }

  const handleCopyFromGroup = () => {
    if (group && !copyFromGroup ) {
      setNewCompanyName(group.name + ' empresa');
      // setCnpj(group.cnpj); // Assuming you want to clear this if copying from group
      setNewCompanyPontoFocal(group.pontoFocal); // Same here
      setNewCompanyTelefone(group.telefone); // Same here
      setNewCompanyEmail(group.email); // Same here
      setNewCompanyCep(group.cep);
      setNewCompanyPais(group.pais);
      setNewCompanyEstado(group.estado);
      setNewCompanyCidade(group.cidade);
      setNewCompanyBairro(group.bairro);
      setNewCompanyLogradouro(group.logradouro);
    } else {
      // Optionally, clear fields if switch is turned off
      setNewCompanyName('');
      setCnpj('');
      setNewCompanyPontoFocal('');
      setNewCompanyTelefone('');
      setNewCompanyEmail('');
      setNewCompanyCep('');
      setNewCompanyPais('');
      setNewCompanyEstado('');
      setNewCompanyCidade('');
      setNewCompanyBairro('');
      setNewCompanyLogradouro('');
    }
  };

  useEffect(() => {
    if (newVariableTwin) {
      DbService.get_sensors_from_company(newVariableTwin.company_id).then((res: any) => {
        setNewVariableSensorsList(res.digitalTwins.map((twin: any) => twin.sensors).flat());
      })
    }
  }, [newVariableTwin])

  useEffect(() => {
    if (newDtwinId) {
      setNewDtwinGraph(digitalTwinsList.find(twin => twin.id === newDtwinId));
    }
  }, [newDtwinId])

  useEffect(() => {
    if (newDtwinGraph) {
      setNewDtwinId(newDtwinGraph.id);

      DbService.get_sensors_from_company(newDtwinGraph.company_id).then((res: any) => {
        setNewGraphSensorsList(res.digitalTwins.map((twin: any) => twin.sensors).flat());
      })
    }
  }, [newDtwinGraph])

  useEffect(() => {
    
    if (id) {
      DbService.get_group(id).then((data) => {
        setGroup(data);
        setListedCompanies(data.companies);
        setCompanies(data.companies);
      
        const ids = data.companies.map((companie) => companie.id)
        data.companies.map((id) => DbService.get_graphs(id).then((data) => {
          setDashboardList(data);
        }))
        DbService.get_digitalTwinsbyGroup(id as any).then((data) => {
          setDigitalTwinsList(data.filter((dt) => ids.includes(dt.company_id)));
        })
      });
      
      DbService.get_users_from_group(id).then((data) => {
        setUsersList(data);
      });
    } }, [id]);

  const cabecalho = (
    <>
    <Menu
        anchorEl={anchorLogo}
              open={openLogo}
              onClose={()=>setOpenLogo(false)}
            sx={{
            
              width: "330px",
            }}
            >
              
              <input
          type="file"
          style={{ display: 'block' }}
          onChange={(event) => handleLogoChange(event)}
        />
        

  {logoPreviewUrl && (
          <div>
            <img
              src={logoPreviewUrl}
              alt="Preview"
              style={{ width: '100%', maxHeight: '300px', padding: '20px' }}
            />
          </div>
        ) }

        
              {selectedLogo && <MenuItem  sx={{width: "100%"}} onClick={() => handleSaveLogo()}>Salvar</MenuItem>}
              {selectedLogo &&  <MenuItem  sx={{width: "100%"}} onClick={() => handleCancelLogo()}>Cancelar</MenuItem>}

              
    </Menu>
    
    {group && (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "row",
        // gap: "50px",
        justifyContent: "space-between",
        alignItems: "start",
        padding: "10px",
      }}
    >
     
      {isEditing ? (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <img
                style={{ maxWidth: "170px" ,objectFit: "cover",cursor: "pointer"}}
                src={group.logo || 'https://www.horizon-fm.fr/wp-content/uploads/2020/05/cropped-LogoVDE-1.png'}
                title="Clique para alterar o logo"
                alt="Logo"
                onClick={(event) =>handleOpenLogoMenu(event)}
              />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px"}}>
            <OutlinedInput
              size="small"
              defaultValue={group.name}
              onChange={(e) => setNewGroupName(e.target.value)}
              placeholder="Nome do Grupo"
              
            />
            <OutlinedInput
              size="small"
              defaultValue={group.cnpj}
              onChange={(e) => setNewCnpj(e.target.value)}
              placeholder="CNPJ"
            />
        </Box>
          <Box sx={{ display: "flex", flexDirection: "column",gap: "10px",marginTop: "50px"  }}>
          <OutlinedInput
            size="small"
            defaultValue={group.logradouro}
            onChange={(e) => setNewLogradouro(e.target.value)}
            placeholder="Endereço"
          />
          <OutlinedInput
            size="small"
            defaultValue={group.bairro}
            onChange={(e) => setNewBairro(e.target.value)}
            placeholder="Bairro"
          />
          <OutlinedInput
            size="small"
            defaultValue={group.cidade}
            onChange={(e) => setNewCidade(e.target.value)}
            placeholder="Cidade"
          />
          <OutlinedInput
            size="small"
            defaultValue={group.estado}
            onChange={(e) => setNewEstado(e.target.value)}
            placeholder="Estado"
          />
          <OutlinedInput
            size="small"
            defaultValue={group.cep}
            onChange={(e) => setNewCep(e.target.value)}
            placeholder="CEP"
          />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "50px" }}>
          <OutlinedInput
            size="small"
            defaultValue={group.email}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="Email"
          />
          <OutlinedInput
            size="small"
            defaultValue={group.telefone}
            onChange={(e) => setNewTelefone(e.target.value)}
            placeholder="Telefone"
          />
          </Box>
          
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
             <img
                style={{ maxWidth: "170px" ,objectFit: "cover",cursor: "pointer"}}
                src={group.logo || 'https://www.horizon-fm.fr/wp-content/uploads/2020/05/cropped-LogoVDE-1.png'}
                alt="Logo"
              />
            <Typography variant="h5" sx={{ display: "flex", flexDirection: "column"}}>
              {group?.name}
            <Typography>CNPJ: {group?.cnpj}</Typography>
            </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", paddingTop: "32px" }}>
            <Typography>Endereço: {group?.logradouro}</Typography>
            <Typography>Bairro: {group?.bairro}</Typography>
            <Typography>Cidade: {group?.cidade} {group.estado ? `- ${group.estado}` : ""}</Typography>
            <Typography>CEP: {group?.cep}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", paddingTop: "32px" }}>
            <Typography>Email: {group?.email}</Typography>
            <Typography>Telefone: {group?.telefone}</Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>

      {isEditing && (
         <Button
         size="small" 
         onClick={() => handleSaveChanges()}
         sx={{
          color: "#124251",
          border: "1px solid #124251", 
          "&:hover": { backgroundColor: "#124251", border: "1px solid #124251", color: "white", opacity: "0.8" }
        }}
       >
         Salvar
       </Button>
      )}
      <Button 
        size="small" 
        endIcon={<Edit />} 
        onClick={() => setIsEditing(!isEditing)} 
        sx={{
          color: "#124251",
          border: "1px solid #124251", 
          "&:hover": { backgroundColor: "#124251", border: "1px solid #124251", color: "white", opacity: "0.8" }
        }}
      >
        {isEditing ? "Cancel" : "Editar"}
      </Button>
      </Box>
    </Paper>
  )}
    </>
  )

  function TwinRow ({twin}: any ) {
    const [sensors  , setSensors] = useState<any>([]);
    const [sensorsFiltered  , setSensorsFiltered] = useState<any>([]);
    const empresa = companies.find(company => company.id === twin.company_id);
    const [open, setOpen] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    useEffect(() => {
      DbService.get_sensors_from_company(twin.company_id).then((res: any) => {
        setSensors(res?.digitalTwins.filter((twinin: any) => twinin.id === twin.id).at(0).sensors)
        setSensorsFiltered(res?.digitalTwins.filter((twinin: any) => twinin.id === twin.id).at(0).sensors)
      })
    }, [twin.company_id, twin.id])

    const getVariableInfo = async (indx: number) => {
      setNewVariableName(sensors[indx].name);
      setNewVariableUnit(sensors[indx].unit.id);
      setNewVariableGranularity(sensors[indx].granularity);
      setNewTypeOfVariable(sensors[indx].variable);
      setNewVariableTypeSensor(sensors[indx].sensor_type);
      setNewVariableSensor(sensors[indx].number);
      setNewVariableDevice(sensors[indx].device_id);
      setNewVariableInfEngLimit(sensors[indx].operation_limits_min_value);
      setNewVariableSupEngLimit(sensors[indx].operation_limits_max_value);
      setNewVariableType(sensors[indx].variable_type);
      setNewVariableInfLimit(sensors[indx].sensor_limits_min_value);
      setNewVariableSupLimit(sensors[indx].sensor_limits_max_value);
      setNewVariableReadAmount(sensors[indx].number_of_readings);
      setNewVariableTime(sensors[indx].reading_time);
      setNewVariableId(sensors[indx].id)
    }
    
    const handleSearchSensors = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const filteredSensors = sensors.filter((sensor) => {
        if (sensor.name.toLowerCase().includes(value.toLowerCase())) {
          return sensor;
        } else {
          return null;
        }
      });
      value === "" ? setSensorsFiltered(sensors) : setSensorsFiltered(filteredSensors);
    };

    const handleDeleteTwin = (id: number) => {
      DbService.deleteDigitalTwin(id).then(() => {
        window.location.reload();
      })
    };
          
        return <>
        <TableRow sx={{ '& > *': { borderBottom: "unset" } ,'&:hover': { backgroundColor: '#f5f5f5' }, cursor: 'pointer'}}>
  
        <TableCell component="th" scope="row">{twin.name}</TableCell>
        <TableCell align="right">{twin.input_utility}</TableCell>
        <TableCell align="right">{sensors?.length}</TableCell>
        <TableCell align="right">{empresa?.name || ''}</TableCell>
        <TableCell align="right" sx={{ display: "flex", gap: "10px" }}>
        <Button
          sx={{color: "#124251" ,border: "1px solid #124251", "&:hover": { backgroundColor: "#124251",border: "1px solid #124251",color: "white", opacity: "0.8" } }}
          aria-label="expand row"
          size="small"
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          onClick={() => setOpen(!open)} 
        >
          {"Visualizar Variaveis"}
        </Button>
        <Button 
          size="small" 
          endIcon={<Edit />} 
          onClick={ () => {
            setUpdateTwinInfo(true);
            getTwinInfo(twin.id);
          }} 
          sx={{
            color: "#124251",
            border: "1px solid #124251", 
            "&:hover": { backgroundColor: "#124251",border: "1px solid #124251",color: "white", opacity: "0.8" } 
          }}
        > 
          Editar
        </Button>    
        <Button 
          size="small"  
          onClick={ () => {
            setOpenConfirmDialog(true);
          }}
          sx={{
            color: "#124251",
            border: "1px solid #124251", 
            "&:hover": { backgroundColor: "red",border: "1px solid red",color: "white", opacity: "0.8" } 
          }}
        >
          <Delete />
        </Button>

        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              
              <FormControl id="sensorsSearch" variant="outlined" style={{ width: "100%",display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  placeholder="Pesquisar variavel"
                  aria-label="weight"
                  type="text"
                  size="small"
                  sx={{ margin: "5px" }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Icon />
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  onChange={handleSearchSensors}
                />
                <Box sx={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                <Button size="small"  onClick={ () => {}} sx={{color: "#124251" ,border: " 1px solid #124251",cursor: "not-allowed", "&:hover": { borderColor: " 1px solid #124251",opacity: "0.8" } }}> 
                  importar em lote
                </Button>
                <Button 
                onClick={ () => {
                  setAddingVariable(true); 
                  setNewVariableTwin(twin);
                  getUnits();
                }}  
                size="small" 
                startIcon={<Add />}  
                sx={{
                  
                  color: "#124251",
                  backgroundColor: "white",
                  border: "1px solid #124251",
                  "&:hover": {
                    backgroundColor: "#124251",
                    color: "white",
                    border: "1px solid #124251",
                  },
                }}> 
                 Adicionar Variável 
                </Button>
                </Box>
                
      {/* <Button style={{backgroundColor: "#124251", color: "white", border: "none", borderRadius: "4px", cursor: "pointer",height: "50px",fontSize: "14px",fontWeight: "500",padding: "0 20px"}} onClick={ () => {}}></Button> */}

      </FormControl>
              
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Variável</TableCell>
                    <TableCell>Unidade</TableCell>
                    <TableCell align="right">Tipo de Variável</TableCell>
                    <TableCell align="right">Granularidade</TableCell>
                    <TableCell align="right">Sensor</TableCell>
                    <TableCell align="right">Dispositivo</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sensorsFiltered?.map((sensor : any, indx: number) => {
                    return <TableRow key={sensor.number + sensor.device_id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#f5f5f5' } ,cursor: "pointer"}}>
                      <TableCell component="th" scope="row">{sensor.name}</TableCell>
                      <TableCell>{sensor.unit.abbreviation}</TableCell>
                      <TableCell align="right">{sensor.variable_type === "monitoring" ? "Monitorada" : "Controle"}</TableCell>
                      <TableCell align="right">{}</TableCell>
                      <TableCell align="right">{sensor.number}</TableCell>
                      <TableCell align="right">{sensor.device_id}</TableCell>
                      <TableCell align="right" sx={{ display: "flex", gap: "10px" }}>
                        <Button 
                          size="small" 
                          endIcon={<Edit />}  
                          onClick={ () => {
                            setEditVariableInfo(true);
                            getVariableInfo(indx);
                            getUnits();
                          }} 
                          sx={{
                            color: "#124251",
                            border: "1px solid #124251", 
                            "&:hover": { backgroundColor: "#124251",border: "1px solid #124251",color: "white", opacity: "0.8" } 
                          }}
                        > 
                          Editar
                        </Button>      
                        <Button 
                          size="small"  
                          onClick={ () => {
                            setDeletedVariableName(sensor.name);
                            setDeletedVariableId(sensor.id);
                            setOpenConfirmDialogSensor(true);
                          }} 
                          sx={{
                            color: "#124251",
                            border: "1px solid #124251", 
                            "&:hover": { backgroundColor: "red",border: "1px solid red",color: "white", opacity: "0.8" } 
                          }}
                        > 
                          <Delete />
                        </Button>
                      </TableCell>
                    </TableRow>
                  }
                  ) }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      <Dialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
        >
          <DialogTitle>Confirmar Exclusão</DialogTitle>
          <DialogContent>
            <Typography>
              Tem certeza de que deseja excluir {twin.name} ? Esta ação é permanente e não pode ser desfeita.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: "white" ,backgroundColor: "red", "&:hover": { backgroundColor: "red",opacity: "0.8" } }} onClick={() => setOpenConfirmDialog(false)} color="primary">
              Cancelar
            </Button>
            <Button sx={{ color: "white" ,backgroundColor: "#124251", "&:hover": { backgroundColor: "#124251", opacity: "0.8" } }} onClick={() => {
              handleDeleteTwin(twin.id)
              setOpenConfirmDialog(false);
            }} color="secondary">
              Deletar
            </Button>
          </DialogActions>
        </Dialog>
      </TableRow>
      
      </>   
 
  }

  const resetForm = () => {
    setAddingGraph(false);
    setNewCompanyGraph(null);
    setNewDtwinGraph(null);
    setNewDtwinId(null);
    setEditGraph(false);
    setNewGraphName('');
    setPosition('');
    setTime1('');
    setTime2(null);
    setIsXTime(false);
    setXAxisSensorId(null);
    setYAxis1(null);
    setYAxis2(null);
    setDefaultVisualization(null);
    setLineVisualization(false);
    setPieVisualization(false);
    setBarVisualization(false);
    setSpeedometerVisualization(false);
    setSpeedometerSensor(null);
    setSpeedometerMin(null);
    setSpeedometerMax(null);
    setSpeedometerBreak1(null);
    setSpeedometerBreak2(null);
    setTimeRange(null);
    setTimeUnits(null);
    setAlertsAlarms([]);
  };

  

  return (

    <Box sx={{ display: "flex",flexDirection: "column",width: "calc(100% - 70px)" }}>

  <TabContext value={valuetab}>
    

      <Tabs  value={valuetab} onChange={handleChange} textColor={theme.palette.mode === "dark" ? "primary" : "secondary"} indicatorColor={theme.palette.mode === "dark" ? "primary" : "secondary"}  >
        <Tab label="Empresas" value="1" />
        <Tab label="Usuários" value="2" />
        <Tab label="Gêmeos Digitais" value="3" />
        <Tab label="Dashboards" value="4" />
      </Tabs>
    


    <TabPanel value="1" >
    
        {cabecalho}

        <Box sx={{  display: "flex", flexDirection: "column", gap: "10px", height: "calc(100% - 120px)", padding: "10px", justifyContent: "space-between",marginTop: "50px" }}>
        
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <OutlinedInput
                id="outlined-adornment-weight"
                placeholder="Pesquisar empresas"
                aria-label="weight"
                type="text"
                size="small"
                sx={{ height: "50px", margin: "5px" }}
                startAdornment={
                  <InputAdornment position="start">
                    <Icon />
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                onChange={handleSearch}
              />
              <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <Button size="small"  onClick={ () => {}} sx={{color: "#124251" ,border: " 1px solid #124251",cursor: "not-allowed", "&:hover": { borderColor: " 1px solid #124251",opacity: "0.8" } }}> 
                  importar em lote
                </Button>
                <Button
                    onClick={() => {
                      setAddingCompany(true);
                    }}
                   
                    size="small"
                    startIcon={<Add />}
                    sx={{color: "#124251" ,border: "1px solid #124251", "&:hover": { backgroundColor: "#124251",border: "1px solid #124251",color: "white", opacity: "0.8" } }}
                    
                  >
                   Adicionar Empresa
                  </Button>
              </Box>
              <Dialog
                open={addingCompany}
                onClose={() => setAddingCompany(false)}
                fullWidth
                maxWidth={"xl"}
                sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <DialogTitle sx={{ textAlign: "start", color: "#124251" }}>
                    {group?.name} {'>'} {newCompanyName ? newCompanyName : 'Nova Empresa '}
                  </DialogTitle>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={copyFromGroup}
                        onChange={(e) => {
                          setCopyFromGroup(e.target.checked);
                          handleCopyFromGroup();
                        }}
                        inputProps={{ 'aria-label': 'Copy from group' }}
                        sx={{ '& .MuiFormControlLabel-label': { color: 'inherit' } }}
                      />
                    }
                    label="Copiar da matriz"
                  />
                </Box>

                <DialogContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "start",
                        color: "#124251",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      Dados Gerais
                    </Typography>

                    {/* {newGroupLogo !== "" ? (
                      <img
                        src={newGroupLogo}
                        alt="Logo do Grupo"
                        style={{
                          width: "140px",
                          height: "78px",
                          backgroundColor: "#124251",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    ) : null} */}

                    <TextField
                      label="Nome da Empresa"
                      type="text"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newCompanyName}
                      onChange={(e) => setNewCompanyName(e.target.value)}
                    />

                    <TextField
                      label="Cnpj"
                      type="text"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={cnpj}
                      onChange={(e) => setCnpj(e.target.value)}
                    />

                    <TextField
                      label="Ponto Focal"
                      type="text"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newCompanyPontoFocal}
                      onChange={(e) => setNewCompanyPontoFocal(e.target.value)}
                    />

                    <TextField
                      label="Telefone"
                      type="text"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newCompanyTelefone}
                      onChange={(e) => setNewCompanyTelefone(e.target.value)}
                    />

                    <TextField
                      label="E-mail"
                      type="text"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newCompanyEmail}
                      onChange={(e) => setNewCompanyEmail(e.target.value)}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "start",
                        color: "#124251",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      Localização
                    </Typography>

                    <TextField
                      label="CEP"
                      type="text"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newCompanyCep}
                      onChange={(e) => setNewCompanyCep(e.target.value)}
                    />

                    <TextField
                      label="País"
                      type="text"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newCompanyPais}
                      onChange={(e) => setNewCompanyPais(e.target.value)}
                    />

                    <TextField
                      label="Estado"
                      type="text"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newCompanyEstado}
                      onChange={(e) => setNewCompanyEstado(e.target.value)}
                    />

                    <TextField
                      label="Cidade"
                      type="text"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newCompanyCidade}
                      onChange={(e) => setNewCompanyCidade(e.target.value)}
                    />

                    <TextField
                      label="Bairro"
                      type="text"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newCompanyBairro}
                      onChange={(e) => setNewCompanyBairro(e.target.value)}
                    />

                    <TextField
                      label="Logradouro"
                      type="text"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newCompanyLogradouro}
                      onChange={(e) => setNewCompanyLogradouro(e.target.value)}
                    />
                  </Box>
                </DialogContent>

                <DialogActions sx={{ justifyContent: "end" }}>
                  <Button
                    sx={{
                      width: "7vw",
                      height: "5vh",
                      color: "#124251",
                      border: "1px solid #124251",
                      "&:hover": {
                        opacity: "0.8",
                        border: "1px solid #0E363E",
                      },
                    }}
                    onClick={() => {
                      setAddingCompany(false);
                      setNewCompanyName('');
                      setNewCompanyEmail('');
                      setNewCompanyCep('');
                      setNewCompanyPais('');
                      setNewCompanyEstado('');
                      setNewCompanyCidade('');
                      setNewCompanyBairro('');
                      setNewCompanyLogradouro('');
                      setNewCompanyPontoFocal('');
                      setNewCompanyTelefone('');
                      setCnpj('');
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    sx={{ 
                      width:"7vw",
                      height:"5vh",
                      color: "white", 
                      backgroundColor: "#124251", 
                      "&:hover": { 
                        opacity: "0.8",
                        backgroundColor: "#0E363E" 
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#B0B0B0",
                        color: "#E0E0E0",
                      }
                    }}   
                    disabled={ !newCompanyName || !newCompanyEmail || !newCompanyCep || !newCompanyPais || !newCompanyEstado || !newCompanyCidade || !newCompanyBairro || !newCompanyLogradouro || !newCompanyPontoFocal || !newCompanyTelefone|| 
                    !cnpj } 
                    onClick={() => handleAddCompany()}
                  >
                    Salvar
                  </Button>
                </DialogActions>
              </Dialog>

          </Box>

          <TableContainer component={Paper}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell>Empresa</TableCell>
                  
                  <TableCell align="right">Localização</TableCell>
                  <TableCell align="right">Usuarios</TableCell>
                  <TableCell align="right">Gêmeos digitais</TableCell>
                  
                  <TableCell align="right"></TableCell>

                </TableRow>
              </TableHead>
              <TableBody>

            {listedCompanies.map((company) => {
            
              return <TableRow key={company.name} sx={{ '&:last-child td, &:last-child th': { border: 0 },'&:hover': { backgroundColor: '#f5f5f5' }, cursor: 'pointer' }}>

                    <TableCell align="left">{company.name}</TableCell>
                    <TableCell align="right">{company.city?.name},{company.city?.state?.name}-{company.city?.state?.country?.name}</TableCell>
                    <TableCell align="right">{company.users?.length}</TableCell>
                    <TableCell align="right">{company.digitalTwins?.length}</TableCell>
                    <TableCell align="right" sx={{ display: "flex", gap: "10px" }}>
                     
                      <Button size="small" endIcon={<Edit />}  
                        onClick={ () => {
                          setUpdateCompanyInfo(true);
                          getCompanyInfo(company.id);
                        }} 
                        sx={{
                          color: "#124251",
                          border: "1px solid #124251", 
                          "&:hover": { backgroundColor: "#124251",border: "1px solid #124251",color: "white", opacity: "0.8" }
                          }}
                      > 
                        Editar
                      </Button>      
                      <Button  onClick={ () => handleConfirmDelete()}
                        size="small"
                        sx={{color: "#124251" ,border: "1px solid #124251", "&:hover": { backgroundColor: "red",border: "1px solid red",color: "white", opacity: "0.8" } }}>
                          <Delete />
                      </Button>
                    </TableCell>

                    <Dialog
                      open={updateCompanyInfo}
                      onClose={() => setUpdateCompanyInfo(false)}
                      fullWidth
                      maxWidth={"xl"}
                      sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <DialogTitle sx={{ textAlign: "start", color: "#124251" }}>
                          {group?.name} {'>'} {newCompanyName ? newCompanyName : 'Editar Empresa '}
                        </DialogTitle>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={copyFromGroup}
                              onChange={(e) => {
                                setCopyFromGroup(e.target.checked);
                                handleCopyFromGroup();
                              }}
                              inputProps={{ 'aria-label': 'Copy from group' }}
                              sx={{ '& .MuiFormControlLabel-label': { color: 'inherit' } }}
                            />
                          }
                          label="Copiar da matriz"
                        />
                      </Box>

                      <DialogContent
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "start",
                              color: "#124251",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            Dados Gerais
                          </Typography>

                          {/* {newGroupLogo !== "" ? (
                            <img
                              src={newGroupLogo}
                              alt="Logo do Grupo"
                              style={{
                                width: "140px",
                                height: "78px",
                                backgroundColor: "#124251",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "start",
                                borderRadius: "4px",
                                objectFit: "cover",
                              }}
                            />
                          ) : null} */}

                          <TextField
                            label="Nome da Empresa"
                            type="text"
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              '& .MuiInputLabel-root': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                                '&.Mui-focused': {
                                  color: theme.palette.mode === "dark" ? "white" : "black",
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'black',
                                },
                              },
                            }}
                            value={newCompanyName}
                            onChange={(e) => setNewCompanyName(e.target.value)}
                          />

                          <TextField
                            label="Cnpj"
                            type="text"
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              '& .MuiInputLabel-root': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                                '&.Mui-focused': {
                                  color: theme.palette.mode === "dark" ? "white" : "black",
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'black',
                                },
                              },
                            }}
                            value={cnpj}
                            onChange={(e) => setCnpj(e.target.value)}
                          />

                          <TextField
                            label="Ponto Focal"
                            type="text"
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              '& .MuiInputLabel-root': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                                '&.Mui-focused': {
                                  color: theme.palette.mode === "dark" ? "white" : "black",
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'black',
                                },
                              },
                            }}
                            value={newCompanyPontoFocal}
                            onChange={(e) => setNewCompanyPontoFocal(e.target.value)}
                          />

                          <TextField
                            label="Telefone"
                            type="text"
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              '& .MuiInputLabel-root': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                                '&.Mui-focused': {
                                  color: theme.palette.mode === "dark" ? "white" : "black",
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'black',
                                },
                              },
                            }}
                            value={newCompanyTelefone}
                            onChange={(e) => setNewCompanyTelefone(e.target.value)}
                          />

                          <TextField
                            label="E-mail"
                            type="text"
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              '& .MuiInputLabel-root': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                                '&.Mui-focused': {
                                  color: theme.palette.mode === "dark" ? "white" : "black",
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'black',
                                },
                              },
                            }}
                            value={newCompanyEmail}
                            onChange={(e) => setNewCompanyEmail(e.target.value)}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "start",
                              color: "#124251",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            Localização
                          </Typography>

                          <TextField
                            label="CEP"
                            type="text"
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              '& .MuiInputLabel-root': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                                '&.Mui-focused': {
                                  color: theme.palette.mode === "dark" ? "white" : "black",
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'black',
                                },
                              },
                            }}
                            value={newCompanyCep}
                            onChange={(e) => setNewCompanyCep(e.target.value)}
                          />

                          <TextField
                            label="País"
                            type="text"
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              '& .MuiInputLabel-root': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                                '&.Mui-focused': {
                                  color: theme.palette.mode === "dark" ? "white" : "black",
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'black',
                                },
                              },
                            }}
                            value={newCompanyPais}
                            onChange={(e) => setNewCompanyPais(e.target.value)}
                          />

                          <TextField
                            label="Estado"
                            type="text"
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              '& .MuiInputLabel-root': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                                '&.Mui-focused': {
                                  color: theme.palette.mode === "dark" ? "white" : "black",
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'black',
                                },
                              },
                            }}
                            value={newCompanyEstado}
                            onChange={(e) => setNewCompanyEstado(e.target.value)}
                          />

                          <TextField
                            label="Cidade"
                            type="text"
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              '& .MuiInputLabel-root': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                                '&.Mui-focused': {
                                  color: theme.palette.mode === "dark" ? "white" : "black",
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'black',
                                },
                              },
                            }}
                            value={newCompanyCidade}
                            onChange={(e) => setNewCompanyCidade(e.target.value)}
                          />

                          <TextField
                            label="Bairro"
                            type="text"
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              '& .MuiInputLabel-root': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                                '&.Mui-focused': {
                                  color: theme.palette.mode === "dark" ? "white" : "black",
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'black',
                                },
                              },
                            }}
                            value={newCompanyBairro}
                            onChange={(e) => setNewCompanyBairro(e.target.value)}
                          />

                          <TextField
                            label="Logradouro"
                            type="text"
                            sx={{
                              width: "100%",
                              marginTop: "10px",
                              '& .MuiInputLabel-root': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                                '&.Mui-focused': {
                                  color: theme.palette.mode === "dark" ? "white" : "black",
                                },
                              },
                              '& .MuiInputBase-input': {
                                color: theme.palette.mode === "dark" ? "white" : "black",
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: 'black',
                                },
                              },
                            }}
                            value={newCompanyLogradouro}
                            onChange={(e) => setNewCompanyLogradouro(e.target.value)}
                          />
                        </Box>
                      </DialogContent>

                      <DialogActions sx={{ justifyContent: "end" }}>
                        <Button
                          sx={{
                            width: "7vw",
                            height: "5vh",
                            color: "#124251",
                            border: "1px solid #124251",
                            "&:hover": {
                              opacity: "0.8",
                              border: "1px solid #0E363E",
                            },
                          }}
                          onClick={() => {
                            setUpdateCompanyInfo(false);
                            setNewCompanyName('');
                            setNewCompanyEmail('');
                            setNewCompanyCep('');
                            setNewCompanyPais('');
                            setNewCompanyEstado('');
                            setNewCompanyCidade('');
                            setNewCompanyBairro('');
                            setNewCompanyLogradouro('');
                            setNewCompanyPontoFocal('');
                            setNewCompanyTelefone('');
                            setCnpj('');
                          }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          sx={{ 
                            width:"7vw",
                            height:"5vh",
                            color: "white", 
                            backgroundColor: "#124251", 
                            "&:hover": { 
                              opacity: "0.8",
                              backgroundColor: "#0E363E" 
                            },
                            "&.Mui-disabled": {
                              backgroundColor: "#B0B0B0",
                              color: "#E0E0E0",
                            }
                          }}   
                          disabled={!newCompanyName || !newCompanyEmail || !newCompanyCep || !newCompanyPais || !newCompanyEstado || !newCompanyCidade || !newCompanyBairro || !newCompanyLogradouro || !newCompanyPontoFocal || !newCompanyTelefone ||
                          !cnpj
                          } 
                          onClick={() => handleUpdateCompany(company.id)}
                        >
                          Salvar
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      open={openConfirmDialog}
                      onClose={() => setOpenConfirmDialog(false)}
                    >
                      <DialogTitle>Confirmar Exclusão</DialogTitle>
                      <DialogContent>
                        <Typography>
                          Tem certeza de que deseja excluir {company.name}? Esta ação é permanente e não pode ser desfeita.
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button sx={{ color: "white" ,backgroundColor: "red", "&:hover": { backgroundColor: "red",opacity: "0.8" } }} onClick={handleCancelDelete} color="primary">
                          Cancelar
                        </Button>
                        <Button sx={{ color: "white" ,backgroundColor: "#124251", "&:hover": { backgroundColor: "#124251", opacity: "0.8" } }} onClick={() => {
                          handleDeleteCompany(company.id);
                          setOpenConfirmDialog(false);
                        }} color="secondary">
                          Deletar
                        </Button>
                      </DialogActions>
                    </Dialog>

                  </TableRow>       
                })}
              </TableBody>

            </Table>

          </TableContainer>
      </Box>
    </TabPanel>

    <TabPanel value="2">
      
    {cabecalho}

        <Box sx={{  display: "flex", flexDirection: "column", gap: "10px", height: "calc(100% - 120px)", padding: "10px", justifyContent: "space-between",marginTop: "50px" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <OutlinedInput
            id="usersSearch"
            placeholder="Pesquisar usuários"
            aria-label="weight"
            type="text"
            size="small"
            sx={{ height: "50px", margin: "5px" }}
            startAdornment={
              <InputAdornment position="start">
                <Icon />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-texst"
            inputProps={{
              "aria-label": "weight",
            }}
            onChange={handleSearchUsers}
          />
          <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <Button size="small"  onClick={ () => {}} sx={{color: "#124251" ,border: " 1px solid #124251",cursor: "not-allowed", "&:hover": { borderColor: " 1px solid #124251",opacity: "0.8" } }}> 
                  importar em lote
                </Button>
            <Button
                onClick={() => {
                  setAddingUser(true);
                }}
                size="small"
                    startIcon={<Add />}
                    sx={{color: "#124251" ,border: "1px solid #124251", "&:hover": { backgroundColor: "#124251",border: "1px solid #124251",color: "white", opacity: "0.8" } }}
              >
                Adicionar Usuário
              </Button>
          </Box>
          <Dialog open={addingUser} onClose={() => setAddingUser(false)} fullWidth maxWidth={"sm"} sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>

            <DialogTitle sx={{ textAlign: "start", color: "#124251" }}>
              {group?.name} {'>'} {newUserName ? newUserName : 'Novo Usuario '}
            </DialogTitle>

            <DialogContent sx={{ display: "flex", justifyContent: "center" ,marginTop: "10px",flexDirection: "column",gap: "10px"}}>
              <p
                style={{  fontSize:"20px", fontWeight:"500", color: "#124251" }}
              >
                Dados Gerais
              </p>

              <TextField
                label="Nome"
                type="text"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
                value={newUserName}
                onChange={(e) => setNewUserName(e.target.value)}
              />

              <TextField
                select
                label="Permissão"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
                value={permitionLevel}
                onChange={(e) => setPermitionLevel(e.target.value)}
              >
                <MenuItem value={'basic'} selected>basico</MenuItem>
                <MenuItem value={'admin'}>administrador</MenuItem>
                {/* <MenuItem value={30}>Thirty</MenuItem> */}
              </TextField>

              <TextField      
                select
                label="Empresa"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
                value={companie} 
                onChange={(e) => setCompanie(e.target.value)}
              >
                {companies.map((company: any) => (
                  <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                ))}
                {/* <MenuItem value={30}>Thirty</MenuItem> */}
              </TextField>

              <TextField
                label="Telefone"
                type="text"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
                value={newUserTelefone}
                onChange={(e) => setNewUserTelefone(e.target.value)}
              />

              <TextField
                label="E-mail"
                type="text"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
              />
            </DialogContent>

            <DialogActions sx={{ justifyContent: "end" }}>
              <Button sx={{ 
                  width:"7vw",
                  height:"5vh",
                  color: "#124251", 
                  border: "1px solid #124251",  // Adiciona a borda com a cor #124251
                  "&:hover": { 
                    opacity: "0.8",
                    border: "1px solid #0E363E"  // Altera a borda para uma cor mais escura ao passar o mouse
                  } 
                }} onClick={() => {
                setNewUserName('');
                setNewUserEmail('');
                setPermitionLevel('');
                setCompanie('');
                setNewUserTelefone('');
                setAddingUser(false)
              }}>Cancelar</Button>
              <Button 
                sx={{ 
                  width:"7vw",
                  height:"5vh",
                  color: "white", 
                  backgroundColor: "#124251", 
                  "&:hover": { 
                    opacity: "0.8",
                    backgroundColor: "#0E363E"
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#B0B0B0", 
                    color: "#E0E0E0", 
                  }
                }}   
                disabled={ !newUserName || !permitionLevel || !companie || !newUserTelefone || !newUserEmail } 
                onClick={() => handleAddUser()}
              >
                Salvar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog 
            open={editUserInfo} 
            onClose={() => setEditUserInfo(false)} 
            fullWidth 
            maxWidth={"sm"} 
            sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >

            <DialogTitle sx={{ textAlign: "start", color: "#124251" }}>
              {group?.name} {'>'} {newUserName ? newUserName : 'Editar Usuario '}
            </DialogTitle>

            <DialogContent sx={{ display: "flex", justifyContent: "center" ,flexDirection: "column",gap: "10px"}}>
              <p
                style={{  fontSize:"20px", fontWeight:"500", color: "#124251" }}
              >
                Dados Gerais
              </p>
           

              <TextField
                label="Nome"
                type="text"
                sx={{
                  width: "100%",
                  
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
                value={newUserName}
                onChange={(e) => setNewUserName(e.target.value)}
              />

              <TextField
                select
                label="Permissão"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
                value={permitionLevel}
                onChange={(e) => setPermitionLevel(e.target.value)}
              >
                <MenuItem value={'basic'} selected>basico</MenuItem>
                <MenuItem value={'admin'}>administrador</MenuItem>
              </TextField>

              <TextField      
                select
                label="Empresa"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
                value={companie} 
                onChange={(e) => setCompanie(e.target.value)}
              >
                {companies.map((company: any) => (
                  <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                ))}
                {/* <MenuItem value={30}>Thirty</MenuItem> */}
              </TextField>

              <TextField
                label="Telefone"
                type="text"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
                value={newUserTelefone}
                onChange={(e) => setNewUserTelefone(e.target.value)}
              />

              <TextField
                label="E-mail"
                type="text"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
              />
            </DialogContent>

            <DialogActions sx={{ justifyContent: "end" }}>
              <Button 
                sx={{ 
                  width:"7vw",
                  height:"5vh",
                  color: "#124251", 
                  border: "1px solid #124251",  // Adiciona a borda com a cor #124251
                  "&:hover": { 
                    opacity: "0.8",
                    border: "1px solid #0E363E"  // Altera a borda para uma cor mais escura ao passar o mouse
                  } 
                }} 
                onClick={() => {
                  setUserId('');
                  setNewUserName('');
                  setNewUserEmail('');
                  setPermitionLevel('');
                  setCompanie('');
                  setNewUserTelefone('');
                  setEditUserInfo(false)
                }}
              >
                Cancelar
              </Button>

              <Button 
                sx={{ 
                  width:"7vw",
                  height:"5vh",
                  color: "white", 
                  backgroundColor: "#124251", 
                  "&:hover": { 
                    opacity: "0.8",
                    backgroundColor: "#0E363E"
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#B0B0B0", 
                    color: "#E0E0E0", 
                  }
                }}   
                disabled={ !newUserName || !permitionLevel || !companie || !newUserTelefone || !newUserEmail } 
                onClick={() => {handleUpdateUser()}}
              >
                Salvar
              </Button>
            </DialogActions>
          </Dialog>

              </Box>

              <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
            
             
              <TableCell align="left">Nome</TableCell>
              <TableCell align="right">E-mail</TableCell>
              <TableCell align="right">Empresa</TableCell>
              <TableCell align="right">Nível de permissão</TableCell>
              <TableCell align="right">Condição</TableCell>
              <TableCell align="right"></TableCell>

            </TableRow>
          </TableHead>
          <TableBody sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
            {usersList?.sort((a, b) => {
                if (a.permition_level === 'admin' && b.permition_level !== 'admin') {
                  return -1;
                } else if (a.permition_level !== 'admin' && b.permition_level === 'admin') {
                  return 1;
                } else {
                  return a.id - b.id;
                }
              } ).map((user) => {
              return <TableRow
                key={user.name}
                sx={{'&:hover': { backgroundColor: '#f5f5f5' }, cursor: 'pointer'}} 
              >
                
               
               <TableCell align="left"> {user.name} </TableCell>
                <TableCell align="right">{user.email}</TableCell>
               <TableCell align="right">{ user.companies?.length > 0 ? user.companies[0].name : ''}</TableCell>
               <TableCell align="right">{user.permition_level === 'admin' ? 'Administrador' : 'Basico'}</TableCell>
                <TableCell align="right">{user.is_active ? 'Ativo' : 'Inativo'}</TableCell>
                <TableCell align="right" sx={{display:'flex',justifyContent:'end',alignItems:'center',gap:'10px'}}>
                <Button 
                  size="small" 
                  endIcon={<Edit />}  
                  onClick={() => { 
                    setEditUserInfo(true);
                    getUserInfo(user.id);
                  }} 
                  sx={{
                    color: "#124251",
                    border: "1px solid #124251", 
                    "&:hover": { backgroundColor: "#124251",border: "1px solid #124251",color: "white", opacity: "0.8" } 
                  }}   
                > 
                  Editar
                </Button> 
                <Button  onClick={ () => handleConfirmDeleteUser( user.id )}
                size="small"
          sx={{color: "#124251" ,border: "1px solid #124251", "&:hover": { backgroundColor: "red",border: "1px solid red",color: "white", opacity: "0.8" } }}>
            <Delete />
          </Button>
          </TableCell>
          <Dialog
          open={openConfirmDialogUser}
          onClose={() => setOpenConfirmDialogUser(false)}
        >
          <DialogTitle>Confirmar Exclusão</DialogTitle>
          <DialogContent>
            <Typography>
              Tem certeza de que deseja excluir este usuário? Esta ação é permanente e não pode ser desfeita.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: "white" ,backgroundColor: "red", "&:hover": { backgroundColor: "red",opacity: "0.8" } }} onClick={() => setOpenConfirmDialogUser(false)} color="primary">
              Cancelar
            </Button>
            <Button sx={{ color: "white" ,backgroundColor: "#124251", "&:hover": { backgroundColor: "#124251", opacity: "0.8" } }} onClick={() => {
              handleDeleteUser(user.id);
              setOpenConfirmDialogUser(false);
            }} color="secondary">
              Deletar
            </Button>
          </DialogActions>
        </Dialog>

              </TableRow>
              
  })}
          </TableBody>
        </Table>
      </TableContainer>
      
              


          </Box>

    </TabPanel>

    <TabPanel value="3">
    {cabecalho}

    <Box sx={{  display: "flex", flexDirection: "column", gap: "10px", height: "calc(100% - 120px)", padding: "10px", justifyContent: "space-between",marginTop: "50px" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <OutlinedInput
            // id="outlined-adornment-weight"
            placeholder="Pesquisar gêmeo"
            aria-label="weight"
            type="text"
            size="small"
            sx={{ height: "50px", margin: "5px" }}
            startAdornment={
              <InputAdornment position="start">
                <Icon />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            onChange={() => {}}
          />
          <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <Button size="small"  onClick={ () => {}} sx={{color: "#124251" ,border: " 1px solid #124251",cursor: "not-allowed", "&:hover": { borderColor: " 1px solid #124251",opacity: "0.8" } }}> 
                  importar em lote
                </Button>
            <Button
                onClick={() => {
                  setAddingTwin(true);
                }}
                startIcon={ <Add /> }
                size="small" 
                sx={{color: "#124251" ,border: "1px solid #124251", "&:hover": { backgroundColor: "#124251",border: "1px solid #124251",color: "white", opacity: "0.8" } }}
                
              >
                 Adicionar Gêmeo
              </Button>
          </Box>
        <Dialog open={addingTwin} onClose={() => setAddingTwin(false)} fullWidth maxWidth={"sm"} sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>

          <DialogTitle sx={{ textAlign: "start", color: "#124251" }}>{group && group.name[0].toUpperCase() + group.name.slice(1)} {'>'} {newTwinName ? newTwinName : 'Novo Gêmeo Digital '}</DialogTitle>

          <DialogContent sx={{ display: "flex", justifyContent: "center" ,marginTop: "0",flexDirection: "column",gap: "10px"}}>
            <p
              style={{  fontSize:"20px", fontWeight:"500", color: "#124251" }}
            >
              Dados Gerais
            </p>

            <TextField
              label="Nome"
              type="text"
              sx={{
                width: "100%",
                marginTop: "10px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newTwinName}
              onChange={(e) => setNewTwinName(e.target.value)}
            />

            <Stack spacing={1} sx={{ marginTop: "10px" }}>
              <TextField
                variant="outlined"
                label="Ativo"
                value={ativeValue}
                onChange={(e) => setAtiveValue(e.target.value)}
                // onKeyDown={handleKeyDown}
                sx={{
                  width: "100%",
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
              />
              {/* <Stack direction="row" spacing={1}>
                {newActiveChips.map((chip) => (
                  <Chip
                    key={chip}
                    label={chip}
                    onDelete={handleDeleteChip(chip)}
                  />
                ))}
              </Stack> */}
            </Stack>

            <TextField
              label="Utilidade de entrada"
              type="text"
              sx={{
                width: "100%",
                marginTop: "10px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newTwinIn}
              onChange={(e) => setNewTwinIn(e.target.value)}
            />

            <TextField
              label="Utilidade de saída"
              type="text"
              sx={{
                width: "100%",
                marginTop: "10px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newTwinOut}
              onChange={(e) => setNewTwinOut(e.target.value)}
            />

            <TextField
              select
              label="Empresa"
              sx={{
                width: "100%",
                marginTop: "10px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={companieTwin}
              onChange={(e) => setCompanieTwin(e.target.value)}
            >
              {companies.map((company: any) => {
                return <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              })}
            </TextField>
          </DialogContent>  
          
          <DialogActions sx={{ justifyContent: "end", marginTop: "10vh", padding: "1.25rem" }}>
            <Button 
              sx={{ 
                width:"7vw",
                height:"5vh",
                color: "#124251", 
                border: "1px solid #124251",  // Adiciona a borda com a cor #124251
                "&:hover": { 
                  opacity: "0.8",
                  border: "1px solid #0E363E"  // Altera a borda para uma cor mais escura ao passar o mouse
                } 
              }}
              onClick={() => {
                setNewTwinName('');
                setAtiveValue('');
                setNewTwinIn('');
                setNewTwinOut('');
                setCompanieTwin('');
                setAddingTwin(false)
              }}
            >
              Cancelar
            </Button>

            <Button 
              sx={{ 
                width:"7vw",
                height:"5vh",
                color: "white", 
                backgroundColor: "#124251",  // Fundo com a cor #124251
                "&:hover": { 
                  opacity: "0.8",
                  backgroundColor: "#0E363E"  // Altera o fundo para um tom mais escuro ao passar o mouse
                },
                "&.Mui-disabled": {
                  backgroundColor: "#B0B0B0",  // Fundo cinza quando desabilitado
                  color: "#E0E0E0",  // Cor do texto mais clara quando desabilitado
                }
              }}  
              onClick={() => handleAddTwin()}
              disabled={!(newTwinName && newTwinIn && newTwinOut && companieTwin && ativeValue)}
            >
              Salvar
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog 
          open={updateTwinInfo} 
          onClose={() => setUpdateTwinInfo(false)} 
          fullWidth 
          maxWidth={"sm"} 
          sx={{ 
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <DialogTitle sx={{ textAlign: "start", color: "#124251" }}>{group && group.name[0].toUpperCase() + group.name.slice(1)} {'>'} {newTwinName ? newTwinName : 'Editar Gêmeo Digital '}</DialogTitle>

          <DialogContent sx={{ display: "flex", justifyContent: "center" ,marginTop: "0",flexDirection: "column",gap: "10px"}}>
        
              <p
              style={{  fontSize:"20px", fontWeight:"500", color: "#124251" }}
              >
                Dados Gerais
              </p>

            <TextField
              label="Nome"
              type="text"
              sx={{
                width: "100%",
                marginTop: "10px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newTwinName}
              onChange={(e) => setNewTwinName(e.target.value)}
            />

            <Stack spacing={1} sx={{ marginTop: "10px" }}>
              <TextField
                variant="outlined"
                label="Ativo"
                value={ativeValue}
                onChange={(e) => setAtiveValue(e.target.value)}
                // onKeyDown={handleKeyDown}
                sx={{
                  width: "100%",
                  '& .MuiInputLabel-root': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    '&.Mui-focused': {
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }}
              />
              {/* <Stack direction="row" spacing={1}>
                {newActiveChips.map((chip) => (
                  <Chip
                    key={chip}
                    label={chip}
                    onDelete={handleDeleteChip(chip)}
                  />
                ))}
              </Stack> */}
            </Stack>

            <TextField
              label="Utilidade de entrada"
              type="text"
              sx={{
                width: "100%",
                marginTop: "10px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newTwinIn}
              onChange={(e) => setNewTwinIn(e.target.value)}
            />

            <TextField
              label="Utilidade de saída"
              type="text"
              sx={{
                width: "100%",
                marginTop: "10px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newTwinOut}
              onChange={(e) => setNewTwinOut(e.target.value)}
            />

            <TextField
              select
              label="Empresa"
              sx={{
                width: "100%",
                marginTop: "10px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={companieTwin}
              onChange={(e) => setCompanieTwin(e.target.value)}
            >
              {companies.map((company: any) => {
                return <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              })}
            </TextField>
          </DialogContent>  
          
          <DialogActions sx={{ justifyContent: "end", marginTop: "10vh", padding: "1.25rem" }}>
            <Button 
              sx={{ 
                width:"7vw",
                height:"5vh",
                color: "#124251", 
                border: "1px solid #124251",  // Adiciona a borda com a cor #124251
                "&:hover": { 
                  opacity: "0.8",
                  border: "1px solid #0E363E"  // Altera a borda para uma cor mais escura ao passar o mouse
                } 
              }}
              onClick={() => {
                setNewTwinName('');
                setAtiveValue('');
                setNewTwinIn('');
                setNewTwinOut('');
                setCompanieTwin('');
                setUpdateTwinInfo(false)
              }}
            >
              Cancelar
            </Button>

            <Button 
              sx={{ 
                width:"7vw",
                height:"5vh",
                color: "white", 
                backgroundColor: "#124251",  // Fundo com a cor #124251
                "&:hover": { 
                  opacity: "0.8",
                  backgroundColor: "#0E363E"  // Altera o fundo para um tom mais escuro ao passar o mouse
                },
                "&.Mui-disabled": {
                  backgroundColor: "#B0B0B0",  // Fundo cinza quando desabilitado
                  color: "#E0E0E0",  // Cor do texto mais clara quando desabilitado
                }
              }}  
              onClick={() => {handleUpdateTwin(updatedTwinId)}}
              disabled={!(newTwinName && newTwinIn && newTwinOut && companieTwin && ativeValue)}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>

        </Box>

        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{}}>
              {/* <TableCell align="right">Tipo</TableCell> */}
              
              <TableCell align="left">Gêmeo digital</TableCell>
              <TableCell align="right">Ultilidades de entrada</TableCell>
              <TableCell align="right">Variáveis</TableCell>
              <TableCell align="right">Empresa</TableCell>
              <TableCell align="right"></TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {digitalTwinsList?.sort((a, b) => {
    if (a.permition_level === 'admin' && b.permition_level !== 'admin') {
      return -1;
    } else if (a.permition_level !== 'admin' && b.permition_level === 'admin') {
      return 1;
    } else {
      return a.id - b.id;
    }
  } ).map( (twin) => {
              return <TwinRow key={twin} twin={twin} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
      
              


    </Box>

    </TabPanel>

    <Dialog 
      open={addingVariable} 
      onClose={() => setAddingVariable(false)} 
      fullWidth 
      maxWidth={"lg"} 
      sx={{ 
        '& .MuiBackdrop-root': { 
          backgroundColor: 'rgba(0, 0, 0, 0.8)'
        }
      }}
    >

      <DialogTitle sx={{ textAlign: "start", color: "#124251" }}>
        {group?.name} {'>'} {newVariableName ? newVariableName : 'Nova Variável '}
      </DialogTitle>

      <DialogContent sx={{ display: "flex", flexDirection: "row", marginTop: "10px", gap: "10px" }}>
        <div style={{ padding: "10px" }}>
          <p style={{ color:"#124251", fontSize:"1.25rem", fontWeight:"500", marginTop:"5vh", marginBottom: "5vh" }}>
            Dados Gerais
          </p>

          <TextField
            label="Variável"
            type="text"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableName}
            onChange={(e) => setNewVariableName(e.target.value)}
          />

          <div style={{ display: "flex", flexDirection: "row" }}>
            <TextField
              select
              label="Unidade"
              sx={{
                width: "100%",
                marginTop: "10px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableUnit}
              onChange={(e) => setNewVariableUnit(e.target.value)}
            >
              {Array.from(new Set(units.map(unit => unit.abbreviation)))
              .map(abbreviation => {
                const unit = units.find(unit => unit.abbreviation === abbreviation);
                return (
                  <MenuItem key={unit.id} value={unit.id}>
                    {abbreviation}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              label="Granularidade"
              type="number"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginLeft: "5px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableGranularity}
              onChange={(e) => setNewVariableGranularity(e.target.value)}
            />
          </div>

          <TextField
            label="Tipo de variável"
            type="text"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newTypeOfVariable}
            onChange={(e) => setNewTypeOfVariable(e.target.value)}
          />

          <TextField
            select
            label="Tipo de sensor"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableTypeSensor}
            onChange={(e) => setNewVariableTypeSensor(e.target.value)}
          >
            <MenuItem key={0} value={"Manual"}>
              Leitura manual
            </MenuItem>

            <MenuItem key={1} value={"Online"}>
              Leitura Online
            </MenuItem>

            <MenuItem key={2} value={"Calculado"}>
              Calculado
            </MenuItem>
          </TextField>

          <TextField
            select
            label="Sensor"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableSensor}
            onChange={(e) => setNewVariableSensor(e.target.value)}
            disabled={newVariableTypeSensor === 'Manual' || newVariableTypeSensor === null}
          >
            {Array.from(new Set(newVariableSensorsList.map((sensor: any) => sensor.number)))
              .sort((a: any, b: any) => a - b)
              .map((sensorNumber: any, index: number) => (
                <MenuItem key={index} value={sensorNumber}>
                  {sensorNumber}
                </MenuItem>
              ))
            }
          </TextField>

          <TextField
            select
            label="Dispositivo"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableDevice}
            onChange={(e) => setNewVariableDevice(e.target.value)}
            disabled={newVariableTypeSensor === 'Manual' || newVariableTypeSensor === null}
          >
            {Array.from(new Set(newVariableSensorsList.map((sensor: any) => sensor.device_id)))
              .sort((a: any, b: any) => a - b)
              .map((sensorDevice: any, index: number) => (
                <MenuItem key={index} value={sensorDevice}>
                  {sensorDevice}
                </MenuItem>
              ))
            }
          </TextField>

          <TextField
            label="Limite de engenharia inferior"
            type="number"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableInfEngLimit}
            onChange={(e) => setNewVariableInfEngLimit(e.target.value)}
          />

          <TextField
            label="Limite de engenharia superior"
            type="number"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableSupEngLimit}
            onChange={(e) => setNewVariableSupEngLimit(e.target.value)}
          />
        </div>

        <div  style={{ padding: "10px" }}>
          <div>
            <p style={{ color:"#124251", fontSize:"1.25rem", fontWeight:"500", marginTop:"5vh", marginBottom:"0px" }}>
              Tipo
              <p style={{ fontSize: "12px" }}>Para KPIs é obrigatório informar pelo menos um dos limites</p>
            </p>

            <RadioGroup
              row
              value={newVariableType}
              onChange={(e) => setNewVariableType(e.target.value)}
            >
              <FormControlLabel
                value="kpi"
                control={
                  <Radio 
                    sx={{ 
                      '&.Mui-checked': {
                        color: 'black' // Fica preto quando selecionado
                      },
                      '& .MuiSvgIcon-root': { 
                        fontSize: 18 
                      }
                    }}
                  />
                }
                label={<span style={{ fontSize: '0.875rem' }}>KPI</span>}
              />
              <FormControlLabel
                value="control"
                control={
                  <Radio 
                    sx={{ 
                      '&.Mui-checked': {
                        color: 'black' // Fica preto quando selecionado
                      },
                      '& .MuiSvgIcon-root': { 
                        fontSize: 18 
                      }
                    }}
                  />
                }
                label={<span style={{ fontSize: '0.875rem' }}>Controle</span>}
              />
              <FormControlLabel
                value="monitoring"
                control={
                  <Radio 
                    sx={{ 
                      '&.Mui-checked': {
                        color: 'black' // Fica preto quando selecionado
                      },
                      '& .MuiSvgIcon-root': { 
                        fontSize: 18 
                      }
                    }}
                  />
                }
                label={<span style={{ fontSize: '0.875rem' }}>Monitoramento</span>}
              />
            </RadioGroup>

            <TextField
              label="Limite inferior"
              type="number"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginLeft: "5px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableInfLimit}
              onChange={(e) => setNewVariableInfLimit(e.target.value)}
            />

            <TextField
              label="Limite superior"
              type="number"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginLeft: "5px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableSupLimit}
              onChange={(e) => setNewVariableSupLimit(e.target.value)}
            />
          </div>

          <div>
          <p style={{ color:"#124251", fontSize:"1.25rem", fontWeight:"500", marginTop:"3vh", marginBottom:"0px" }}>
            Regras para emissão de alarmes
            <p style={{ fontSize: "12px" }}>Para KPIs é obrigatório informar pelo menos uma regra</p>
          </p>

          <TextField
              label="Quantidade de leituras"
              type="number"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginLeft: "5px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableReadAmount}
              onChange={(e) => setNewVariableReadAmount(e.target.value)}
            />

            <TextField
              label="Tempo (min)"
              type="number"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginLeft: "5px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableTime}
              onChange={(e) => setNewVariableTime(e.target.value)}
            />
          </div>
        </div>
        
      </DialogContent>  
      
      <DialogActions sx={{ justifyContent: "end", marginTop: "2vh", padding: "1.25rem" }}>
        <Button 
          sx={{ 
            width:"7vw",
            height:"5vh",
            color: "#124251", 
            border: "1px solid #124251",
            "&:hover": { 
              opacity: "0.8",
              border: "1px solid #0E363E"
            } 
          }}
          onClick={() => {
            setAddingVariable(false);
            setNewVariableName(null);
            setNewVariableUnit(null);
            setNewVariableGranularity(null);
            setNewTypeOfVariable(null);
            setNewVariableTypeSensor(null);
            setNewVariableSensor(null);
            setNewVariableInfEngLimit(null);
            setNewVariableSupEngLimit(null);
            setNewVariableType(null);
            setNewVariableInfLimit(null);
            setNewVariableSupLimit(null);
            setNewVariableReadAmount(null);
            setNewVariableTime(null);
          }}
        >
          Cancelar
        </Button>

        <Button 
          sx={{ 
            width:"7vw",
            height:"5vh",
            color: "white", 
            backgroundColor: "#124251",  // Fundo com a cor #124251
            "&:hover": { 
              opacity: "0.8",
              backgroundColor: "#0E363E"  // Altera o fundo para um tom mais escuro ao passar o mouse
            },
            "&.Mui-disabled": {
              backgroundColor: "#B0B0B0",  // Fundo cinza quando desabilitado
              color: "#E0E0E0",  // Cor do texto mais clara quando desabilitado
            }
          }}  
          onClick={() => handleAddVariable()}
          disabled={ (newVariableType !== "kpi") ? !(newVariableName && newVariableUnit && newVariableGranularity && newTypeOfVariable && newVariableTypeSensor && 
          newVariableDevice &&
          newVariableInfEngLimit && newVariableSupEngLimit) :  !(newVariableName && newVariableUnit && newVariableGranularity && newTypeOfVariable && newVariableTypeSensor && newVariableInfEngLimit && newVariableSupEngLimit && (newVariableInfLimit || newVariableSupLimit) && (newVariableReadAmount || newVariableTime))}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog 
      open={editVariableInfo} 
      onClose={() => setEditVariableInfo(false)} 
      fullWidth 
      maxWidth={"lg"} 
      sx={{ 
        '& .MuiBackdrop-root': { 
          backgroundColor: 'rgba(0, 0, 0, 0.8)'
        }
      }}
    >
      <DialogTitle sx={{ textAlign: "start", color: "#124251" }}>
        {group?.name} {'>'} {newVariableName ? newVariableName : 'Editar Variável '}
      </DialogTitle>

      <DialogContent sx={{ display: "flex", flexDirection: "row", marginTop: "10px", gap: "10px"}}>
        <div style={{ padding: "10px" }}>
          <p style={{ color:"#124251", fontSize:"1.25rem", fontWeight:"500", marginTop:"5vh", marginBottom: "5vh" }}>
            Dados Gerais
          </p>

          <TextField
            label="Variável"
            type="text"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableName}
            onChange={(e) => setNewVariableName(e.target.value)}
          />

          <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
              select
              label="Unidade"
              sx={{
                width: "100%",
                marginTop: "10px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableUnit}
              onChange={(e) => setNewVariableUnit(e.target.value)}
            >
              {Array.from(new Set(units.map(unit => unit.abbreviation)))
              .map(abbreviation => {
                const unit = units.find(unit => unit.abbreviation === abbreviation);
                return (
                  <MenuItem key={unit.id} value={unit.id}>
                    {abbreviation}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              label="Granularidade"
              type="number"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginLeft: "5px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableGranularity}
              onChange={(e) => setNewVariableGranularity(e.target.value)}
            />
          </div>

          <TextField
            label="Tipo de variável"
            type="text"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newTypeOfVariable}
            onChange={(e) => setNewTypeOfVariable(e.target.value)}
          />

          <TextField
            select
            label="Tipo de sensor"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableTypeSensor}
            onChange={(e) => setNewVariableTypeSensor(e.target.value)}
          >
            <MenuItem key={0} value={"Manual"}>
              Leitura manual
            </MenuItem>

            <MenuItem key={1} value={"Online"}>
              Leitura Online
            </MenuItem>

            <MenuItem key={2} value={"Calculado"}>
              Calculado
            </MenuItem>
          </TextField>

          <TextField
            select
            label="Sensor"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableSensor}
            onChange={(e) => setNewVariableSensor(e.target.value)}
            disabled={newVariableTypeSensor === 'Manual' || newVariableTypeSensor === null}
          >
            {Array.from(new Set(newVariableSensorsList.map((sensor: any) => sensor.number)))
              .sort((a: any, b: any) => a - b)
              .map((sensorNumber: any, index: number) => (
                <MenuItem key={index} value={sensorNumber}>
                  {sensorNumber}
                </MenuItem>
              ))
            }
          </TextField>

          <TextField
            select
            label="Dispositivo"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableDevice}
            onChange={(e) => setNewVariableDevice(e.target.value)}
            disabled={newVariableTypeSensor === 'Manual' || newVariableTypeSensor === null}
          >
            {Array.from(new Set(newVariableSensorsList.map((sensor: any) => sensor.device_id)))
              .sort((a: any, b: any) => a - b)
              .map((sensorDevice: any, index: number) => (
                <MenuItem key={index} value={sensorDevice}>
                  {sensorDevice}
                </MenuItem>
              ))
            }
          </TextField>
          
          <TextField
            label="Limite de engenharia inferior"
            type="number"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableInfEngLimit}
            onChange={(e) => setNewVariableInfEngLimit(e.target.value)}
          />

          <TextField
            label="Limite de engenharia superior"
            type="number"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& .MuiInputLabel-root': {
                color: theme.palette.mode === "dark" ? "white" : "black",
                '&.Mui-focused': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.mode === "dark" ? "white" : "black",
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
            value={newVariableSupEngLimit}
            onChange={(e) => setNewVariableSupEngLimit(e.target.value)}
          />
        </div>

        <div  style={{ padding: "10px" }}>
          <div>
            <p style={{ color:"#124251", fontSize:"1.25rem", fontWeight:"500", marginTop:"5vh", marginBottom:"0px" }}>
              Tipo
              <p style={{ fontSize: "12px" }}>Para KPIs é obrigatório informar pelo menos um dos limites</p>
            </p>

            <RadioGroup
              row
              value={newVariableType}
              onChange={(e) => setNewVariableType(e.target.value)}
            >
              <FormControlLabel
                value="kpi"
                control={
                  <Radio 
                    sx={{ 
                      '&.Mui-checked': {
                        color: 'black' // Fica preto quando selecionado
                      },
                      '& .MuiSvgIcon-root': { 
                        fontSize: 18 
                      }
                    }}
                  />
                }
                label={<span style={{ fontSize: '0.875rem' }}>KPI</span>}
              />
              <FormControlLabel
                value="control"
                control={
                  <Radio 
                    sx={{ 
                      '&.Mui-checked': {
                        color: 'black' // Fica preto quando selecionado
                      },
                      '& .MuiSvgIcon-root': { 
                        fontSize: 18 
                      }
                    }}
                  />
                }
                label={<span style={{ fontSize: '0.875rem' }}>Controle</span>}
              />
              <FormControlLabel
                value="monitoring"
                control={
                  <Radio 
                    sx={{ 
                      '&.Mui-checked': {
                        color: 'black' // Fica preto quando selecionado
                      },
                      '& .MuiSvgIcon-root': { 
                        fontSize: 18 
                      }
                    }}
                  />
                }
                label={<span style={{ fontSize: '0.875rem' }}>Monitoramento</span>}
              />
            </RadioGroup>

            <TextField
              label="Limite inferior"
              type="number"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginLeft: "5px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableInfLimit}
              onChange={(e) => setNewVariableInfLimit(e.target.value)}
            />

            <TextField
              label="Limite superior"
              type="number"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginLeft: "5px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableSupLimit}
              onChange={(e) => setNewVariableSupLimit(e.target.value)}
            />
          </div>

          <div>
          <p style={{ color:"#124251", fontSize:"1.25rem", fontWeight:"500", marginTop:"3vh", marginBottom:"0px" }}>
            Regras para emissão de alarmes
            <p style={{ fontSize: "12px" }}>Para KPIs é obrigatório informar pelo menos uma regra</p>
          </p>

          <TextField
              label="Quantidade de leituras"
              type="number"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginLeft: "5px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableReadAmount}
              onChange={(e) => setNewVariableReadAmount(e.target.value)}
            />

            <TextField
              label="Tempo (min)"
              type="number"
              sx={{
                width: "100%",
                marginTop: "10px",
                marginLeft: "5px",
                '& .MuiInputLabel-root': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                  '&.Mui-focused': {
                    color: theme.palette.mode === "dark" ? "white" : "black",
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.mode === "dark" ? "white" : "black",
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
              value={newVariableTime}
              onChange={(e) => setNewVariableTime(e.target.value)}
            />
          </div>
        </div>
        
      </DialogContent>  
      
      <DialogActions sx={{ justifyContent: "end", marginTop: "2vh", padding: "1.25rem" }}>
        <Button 
          sx={{ 
            width:"7vw",
            height:"5vh",
            color: "#124251", 
            border: "1px solid #124251",
            "&:hover": { 
              opacity: "0.8",
              border: "1px solid #0E363E"
            } 
          }}
          onClick={() => {
            setEditVariableInfo(false);
            setNewVariableName(null);
            setNewVariableUnit(null);
            setNewVariableGranularity(null);
            setNewTypeOfVariable(null);
            setNewVariableTypeSensor(null);
            setNewVariableSensor(null);
            setNewVariableInfEngLimit(null);
            setNewVariableSupEngLimit(null);
            setNewVariableType(null);
            setNewVariableInfLimit(null);
            setNewVariableSupLimit(null);
            setNewVariableReadAmount(null);
            setNewVariableTime(null);
          }}
        >
          Cancelar
        </Button>

        <Button 
          sx={{ 
            width:"7vw",
            height:"5vh",
            color: "white", 
            backgroundColor: "#124251",
            "&:hover": { 
              opacity: "0.8",
              backgroundColor: "#0E363E"  
            },
            "&.Mui-disabled": {
              backgroundColor: "#B0B0B0",  
              color: "#E0E0E0",  
            }
          }}  
          onClick={() => {handleUpdateVariable(newVariableId)}}
          disabled={ (newVariableType !== "kpi") ? !(newVariableName && newVariableUnit && newVariableGranularity && newTypeOfVariable && newVariableTypeSensor && newVariableInfEngLimit && newVariableSupEngLimit) :  !(newVariableName && newVariableUnit && newVariableGranularity && newTypeOfVariable && newVariableTypeSensor && newVariableInfEngLimit && newVariableSupEngLimit && (newVariableInfLimit || newVariableSupLimit) && (newVariableReadAmount || newVariableTime))}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={openConfirmDialogSensor}
      onClose={() => setOpenConfirmDialogSensor(false)}
    >
      <DialogTitle>Confirmar Exclusão</DialogTitle>
      <DialogContent>
        <Typography>
          Tem certeza de que deseja excluir {deletedVariableName}? Esta ação é permanente e não pode ser desfeita.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: "white" ,backgroundColor: "red", "&:hover": { backgroundColor: "red",opacity: "0.8" } }} onClick={() => setOpenConfirmDialogSensor(false)} color="primary">
          Cancelar
        </Button>
        <Button sx={{ color: "white" ,backgroundColor: "#124251", "&:hover": { backgroundColor: "#124251", opacity: "0.8" } }} onClick={() => {
          setOpenConfirmDialogSensor(false);
          handleDeleteVariable(deletedVariableId);
        }} color="secondary">
          Deletar
        </Button>
      </DialogActions>
    </Dialog>

    <TabPanel value="4">
    {cabecalho}

    <Box sx={{  display: "flex", flexDirection: "column", gap: "10px", height: "calc(100% - 120px)", padding: "10px", justifyContent: "space-between",marginTop: "50px" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <OutlinedInput
            // id="outlined-adornment-weight"
            placeholder="Pesquisar Dashboards"
            aria-label="weight"
            type="text"
            size="small"
            sx={{ height: "50px", margin: "5px" }}
            startAdornment={
              <InputAdornment position="start">
                <Icon />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            onChange={() => {}}
          />
          <Box sx={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                <Button size="small"  onClick={ () => {}} sx={{color: "#124251" ,border: " 1px solid #124251",cursor: "not-allowed", "&:hover": { borderColor: " 1px solid #124251",opacity: "0.8" } }}> 
                  importar em lote
                </Button>
            <Button
                onClick={() => {
                  setAddingGraph(true);
                }}
                sx={{
                  
                  color: "#124251",
                  
                  border: "1px solid #124251",
                  "&:hover": {
                    backgroundColor: "#124251",
                    color: "white",
                    border: "1px solid #124251",
                  },
                }}
                size="small"
                startIcon={<Add />}
              >
                Adicionar Dashboard
              </Button>
              </Box>

              <Dialog 
                open={addingGraph} 
                onClose={() => setAddingGraph(false)} 
                fullWidth 
                maxWidth={"md"}
                sx={{ 
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  overflow: "hidden" 
                }}
              > 
                <DialogTitle sx={{ textAlign: "start", color: "#124251" }}>
                  {group?.name} {'>'} {newGraphName ? newGraphName : 'Novo Dashboard'}
                </DialogTitle>

                <DialogContent 
                  sx={{ display: "flex", flexDirection: "row", marginTop: "-10px", gap: "10px" }}
                >
                  <div style={{ padding: "10px" }}>
                    <Typography
                      sx={{
                        textAlign: "start",
                        color: "#124251",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      Novo Gráfico
                    </Typography>
                    
                    <TextField
                      label="Nome"
                      type="text"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newGraphName}
                      onChange={(e) => setNewGraphName(e.target.value)}
                    />

                    <TextField
                      label="Gemeo"
                      select
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newDtwinGraph}
                      onChange={(e) => setNewDtwinGraph(e.target.value)}
                    >
                      {digitalTwinsList.map((digitalTwins) => (
                        <MenuItem key={digitalTwins.id} value={digitalTwins}>
                          {digitalTwins.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    <div style={{marginTop: "10px"}}>
                      <Typography
                        sx={{
                          textAlign: "start",
                          color: "#124251",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Formato
                      </Typography>
                      
                      <p>Será criado um gráfico por formato selecionado</p>
                    </div>

                    <Stack direction="row" style={{marginLeft: "5px"}}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={speedometerVisualization}
                            onChange={(e) => setSpeedometerVisualization(e.target.checked)}
                            sx={{ 
                              '&.Mui-checked': {
                                color: 'black'
                              },
                              '& .MuiSvgIcon-root': { 
                                fontSize: 18 
                              }
                            }}
                          />
                        }
                        label={<span style={{ fontSize: '0.875rem' }}>Velocímetro</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lineVisualization}
                            onChange={(e) => setLineVisualization(e.target.checked)}
                            sx={{ 
                              '&.Mui-checked': {
                                color: 'black'
                              },
                              '& .MuiSvgIcon-root': { 
                                fontSize: 18 
                              }
                            }}
                          />
                        }
                        label={<span style={{ fontSize: '0.875rem' }}>Linha</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={barVisualization}
                            onChange={(e) => setBarVisualization(e.target.checked)}
                            sx={{ 
                              '&.Mui-checked': {
                                color: 'black'
                              },
                              '& .MuiSvgIcon-root': { 
                                fontSize: 18 
                              }
                            }}
                          />
                        }
                        label={<span style={{ fontSize: '0.875rem' }}>Barras</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={pieVisualization}
                            onChange={(e) => setPieVisualization(e.target.checked)}
                            sx={{ 
                              '&.Mui-checked': {
                                color: 'black'
                              },
                              '& .MuiSvgIcon-root': { 
                                fontSize: 18 
                              }
                            }}
                          />
                        }
                        label={<span style={{ fontSize: '0.875rem' }}>Pizza</span>}
                      />
                    </Stack>

                    <TextField
                      select
                      label="Visualização padrão"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        marginLeft: "5px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={defaultVisualization}
                      onChange={(e) => setDefaultVisualization(e.target.value)}
                    >
                      <MenuItem key={0} value={"speedometer"}>
                        Velocímetro
                      </MenuItem>

                      <MenuItem key={1} value={"line"}>
                        Linha
                      </MenuItem>

                      <MenuItem key={2} value={"bar"}>
                        Barras
                      </MenuItem>

                      <MenuItem key={3} value={"pie"}>
                        Pizza
                      </MenuItem>
                    </TextField>

                    <div style={{marginTop: "10px"}}>
                      <Typography
                        sx={{
                          textAlign: "start",
                          color: "#124251",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Eixos
                      </Typography>
                      
                      <p>Não preencha esses campos caso o formato escolhido tenha sido velocímetro</p>
                    </div>

                    <Stack>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isXTime}
                            onChange={(e) => setIsXTime(e.target.checked)}
                            sx={{ 
                              '&.Mui-checked': {
                                color: 'black'
                              },
                              '& .MuiSvgIcon-root': { 
                                fontSize: 18 
                              }
                            }}
                          />
                        }
                        label={<span style={{ fontSize: '0.875rem' }}>Tempo como eixo x</span>}
                      />
                    </Stack>

                    <TextField
                      label="Eixo X"
                      select
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={xAxisSensorId}
                      onChange={(e) => setXAxisSensorId(e.target.value)}
                      disabled={!newDtwinId || isXTime}
                    >
                      {newGraphSensorsList
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((sensor) => (
                          <MenuItem key={sensor.id} value={sensor.id}>
                            {sensor.name}
                          </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                      label="Eixo Y¹"
                      select
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={yAxis1}
                      onChange={(e) => setYAxis1(e.target.value)}
                      disabled={!xAxisSensorId && (!isXTime || !newDtwinId)}
                    >
                      {newGraphSensorsList
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((sensor) => (
                          <MenuItem key={sensor.id} value={sensor.id}>
                            {sensor.name}
                          </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                      label="Eixo Y²"
                      select
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={yAxis2}
                      onChange={(e) => setYAxis2(e.target.value)}
                      disabled={!yAxis1}
                    >
                      {newGraphSensorsList
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((sensor) => (
                          <MenuItem key={sensor.id} value={sensor.id}>
                            {sensor.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                    
                  <div style={{ padding: "10px" }}>
                    <div>
                      <Typography
                        sx={{
                          textAlign: "start",
                          color: "#124251",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Configurações do Velocímetro
                      </Typography>
                      
                      <p>Preencha os campos caso o formato seja do tipo velocímetro</p>
                    </div>    

                    <TextField
                      label="Sensor principal"
                      select
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={speedometerSensor}
                      onChange={(e) => setSpeedometerSensor(e.target.value)}
                      disabled={!speedometerVisualization || !newDtwinId}
                    >
                      {newGraphSensorsList
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((sensor) => (
                          <MenuItem key={sensor.id} value={sensor.id}>
                            {sensor.name}
                          </MenuItem>
                        ))}
                    </TextField>
                    
                    <TextField
                      label="Limite mínimo"
                      type="number"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={speedometerMin}
                      onChange={(e) => setSpeedometerMin(e.target.value)}
                      disabled={!speedometerVisualization}
                    />

                    <TextField
                      label="Limite máximo"
                      type="number"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={speedometerMax}
                      onChange={(e) => setSpeedometerMax(e.target.value)}
                      disabled={!speedometerVisualization}
                    />

                    <TextField
                      label="Break¹"
                      type="number"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={speedometerBreak1}
                      onChange={(e) => setSpeedometerBreak1(e.target.value)}
                      disabled={!speedometerVisualization}
                    />

                    <TextField
                      label="Break²"
                      type="number"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={speedometerBreak2}
                      onChange={(e) => setSpeedometerBreak2(e.target.value)}
                      disabled={!speedometerVisualization}
                    />

                    <div style={{marginTop: "15px"}}>
                      <Typography
                        sx={{
                          textAlign: "start",
                          color: "#124251",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Configurações de Tempo
                      </Typography>
                      
                      <p>Preencha os campos caso o eixo X seja o tempo</p>
                    </div> 

                    <TextField
                      select
                      label="Período de tempo"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={timeRange}
                      onChange={(e) => setTimeRange(e.target.value)}
                      disabled={!isXTime}
                    >
                      <MenuItem key={0} value={"minutes"}>
                        Minutos
                      </MenuItem>

                      <MenuItem key={1} value={"hours"}>
                        Horas
                      </MenuItem>

                      <MenuItem key={2} value={"days"}>
                        Dias
                      </MenuItem>
                    </TextField>

                    <TextField
                      label="Unidades de tempo"
                      type="number"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={timeUnits}
                      onChange={(e) => setTimeUnits(e.target.value)}
                      disabled={!isXTime}
                    />
                  </div>
                </DialogContent>

                <DialogActions sx={{ justifyContent: "end" }}>
                  <Button
                    sx={{ 
                      width:"7vw",
                      height:"5vh",
                      color: "#124251", 
                      border: "1px solid #124251",
                      "&:hover": { 
                        opacity: "0.8",
                        border: "1px solid #0E363E" 
                      } 
                    }}
                    onClick={() => resetForm()}
                  >
                    Cancelar
                  </Button>
                  <Button 
                    sx={{ 
                      width:"7vw",
                      height:"5vh",
                      color: "white", 
                      backgroundColor: "#124251",
                      "&:hover": { 
                        opacity: "0.8",
                        backgroundColor: "#0E363E"
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#B0B0B0", 
                        color: "#E0E0E0",
                      }
                    }}  
                    disabled={!newGraphName} 
                    onClick={() => {saveGraph()}}
                  >      
                    Salvar
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog 
                open={editGraph} 
                onClose={() => setEditGraph(false)} 
                fullWidth 
                maxWidth={"md"}
                sx={{ 
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  overflow: "hidden" 
                }}
              > 
                <DialogTitle sx={{ textAlign: "start", color: "#124251" }}>
                  {group?.name} {'>'} {newGraphName ? newGraphName : 'Editar Dashboard'}
                </DialogTitle>

                <DialogContent 
                  sx={{ display: "flex", flexDirection: "row", marginTop: "-10px", gap: "10px" }}
                >
                  <div style={{ padding: "10px" }}>
                    <Typography
                      sx={{
                        textAlign: "start",
                        color: "#124251",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      Editar Gráfico
                    </Typography>
                    
                    <TextField
                      label="Nome"
                      type="text"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newGraphName}
                      onChange={(e) => setNewGraphName(e.target.value)}
                    />

                    <TextField
                      label="Gemeo"
                      select
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={newDtwinGraph}
                      onChange={(e) => setNewDtwinGraph(e.target.value)}
                    >
                      {digitalTwinsList.map((digitalTwins) => (
                        <MenuItem key={digitalTwins.id} value={digitalTwins}>
                          {digitalTwins.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    <div style={{marginTop: "10px"}}>
                      <Typography
                        sx={{
                          textAlign: "start",
                          color: "#124251",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Formato
                      </Typography>
                      
                      <p>Será criado um gráfico por formato selecionado</p>
                    </div>

                    <Stack direction="row" style={{marginLeft: "5px"}}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={speedometerVisualization}
                            onChange={(e) => setSpeedometerVisualization(e.target.checked)}
                            sx={{ 
                              '&.Mui-checked': {
                                color: 'black'
                              },
                              '& .MuiSvgIcon-root': { 
                                fontSize: 18 
                              }
                            }}
                          />
                        }
                        label={<span style={{ fontSize: '0.875rem' }}>Velocímetro</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={lineVisualization}
                            onChange={(e) => setLineVisualization(e.target.checked)}
                            sx={{ 
                              '&.Mui-checked': {
                                color: 'black'
                              },
                              '& .MuiSvgIcon-root': { 
                                fontSize: 18 
                              }
                            }}
                          />
                        }
                        label={<span style={{ fontSize: '0.875rem' }}>Linha</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={barVisualization}
                            onChange={(e) => setBarVisualization(e.target.checked)}
                            sx={{ 
                              '&.Mui-checked': {
                                color: 'black'
                              },
                              '& .MuiSvgIcon-root': { 
                                fontSize: 18 
                              }
                            }}
                          />
                        }
                        label={<span style={{ fontSize: '0.875rem' }}>Barras</span>}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={pieVisualization}
                            onChange={(e) => setPieVisualization(e.target.checked)}
                            sx={{ 
                              '&.Mui-checked': {
                                color: 'black'
                              },
                              '& .MuiSvgIcon-root': { 
                                fontSize: 18 
                              }
                            }}
                          />
                        }
                        label={<span style={{ fontSize: '0.875rem' }}>Pizza</span>}
                      />
                    </Stack>

                    <TextField
                      select
                      label="Visualização padrão"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        marginLeft: "5px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={defaultVisualization}
                      onChange={(e) => setDefaultVisualization(e.target.value)}
                    >
                      <MenuItem key={0} value={"speedometer"}>
                        Velocímetro
                      </MenuItem>

                      <MenuItem key={1} value={"line"}>
                        Linha
                      </MenuItem>

                      <MenuItem key={2} value={"bar"}>
                        Barras
                      </MenuItem>

                      <MenuItem key={3} value={"pie"}>
                        Pizza
                      </MenuItem>
                    </TextField>

                    <div style={{marginTop: "10px"}}>
                      <Typography
                        sx={{
                          textAlign: "start",
                          color: "#124251",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Eixos
                      </Typography>
                      
                      <p>Não preencha esses campos caso o formato escolhido tenha sido velocímetro</p>
                    </div>

                    <Stack>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isXTime}
                            onChange={(e) => setIsXTime(e.target.checked)}
                            sx={{ 
                              '&.Mui-checked': {
                                color: 'black'
                              },
                              '& .MuiSvgIcon-root': { 
                                fontSize: 18 
                              }
                            }}
                          />
                        }
                        label={<span style={{ fontSize: '0.875rem' }}>Tempo como eixo x</span>}
                      />
                    </Stack>

                    <TextField
                      label="Eixo X"
                      select
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={xAxisSensorId}
                      onChange={(e) => setXAxisSensorId(e.target.value)}
                      disabled={!newDtwinId || isXTime}
                    >
                      {newGraphSensorsList
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((sensor) => (
                          <MenuItem key={sensor.id} value={sensor.id}>
                            {sensor.name}
                          </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                      label="Eixo Y¹"
                      select
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={yAxis1}
                      onChange={(e) => setYAxis1(e.target.value)}
                      disabled={!xAxisSensorId && (!isXTime || !newDtwinId)}
                    >
                      {newGraphSensorsList
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((sensor) => (
                          <MenuItem key={sensor.id} value={sensor.id}>
                            {sensor.name}
                          </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                      label="Eixo Y²"
                      select
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={yAxis2}
                      onChange={(e) => setYAxis2(e.target.value)}
                      disabled={!yAxis1}
                    >
                      {newGraphSensorsList
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((sensor) => (
                          <MenuItem key={sensor.id} value={sensor.id}>
                            {sensor.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                    
                  <div style={{ padding: "10px" }}>
                    <div>
                      <Typography
                        sx={{
                          textAlign: "start",
                          color: "#124251",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Configurações do Velocímetro
                      </Typography>
                      
                      <p>Preencha os campos caso o formato seja do tipo velocímetro</p>
                    </div>    

                    <TextField
                      label="Sensor principal"
                      select
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={speedometerSensor}
                      onChange={(e) => setSpeedometerSensor(e.target.value)}
                      disabled={!speedometerVisualization || !newDtwinId}
                    >
                      {newGraphSensorsList
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((sensor) => (
                          <MenuItem key={sensor.id} value={sensor.id}>
                            {sensor.name}
                          </MenuItem>
                        ))}
                    </TextField>
                    
                    <TextField
                      label="Limite mínimo"
                      type="number"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={speedometerMin}
                      onChange={(e) => setSpeedometerMin(e.target.value)}
                      disabled={!speedometerVisualization}
                    />

                    <TextField
                      label="Limite máximo"
                      type="number"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={speedometerMax}
                      onChange={(e) => setSpeedometerMax(e.target.value)}
                      disabled={!speedometerVisualization}
                    />

                    <TextField
                      label="Break¹"
                      type="number"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={speedometerBreak1}
                      onChange={(e) => setSpeedometerBreak1(e.target.value)}
                      disabled={!speedometerVisualization}
                    />

                    <TextField
                      label="Break²"
                      type="number"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={speedometerBreak2}
                      onChange={(e) => setSpeedometerBreak2(e.target.value)}
                      disabled={!speedometerVisualization}
                    />

                    <div style={{marginTop: "15px"}}>
                      <Typography
                        sx={{
                          textAlign: "start",
                          color: "#124251",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Configurações de Tempo
                      </Typography>
                      
                      <p>Preencha os campos caso o eixo X seja o tempo</p>
                    </div> 

                    <TextField
                      select
                      label="Período de tempo"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={timeRange}
                      onChange={(e) => setTimeRange(e.target.value)}
                      disabled={!isXTime}
                    >
                      <MenuItem key={0} value={"minutes"}>
                        Minutos
                      </MenuItem>

                      <MenuItem key={1} value={"hours"}>
                        Horas
                      </MenuItem>

                      <MenuItem key={2} value={"days"}>
                        Dias
                      </MenuItem>
                    </TextField>

                    <TextField
                      label="Unidades de tempo"
                      type="number"
                      size="small"
                      sx={{
                        width: "100%",
                        marginTop: "10px",
                        '& .MuiInputLabel-root': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                          '&.Mui-focused': {
                            color: theme.palette.mode === "dark" ? "white" : "black",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: theme.palette.mode === "dark" ? "white" : "black",
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'black',
                          },
                        },
                      }}
                      value={timeUnits}
                      onChange={(e) => setTimeUnits(e.target.value)}
                      disabled={!isXTime}
                    />
                  </div>
                </DialogContent>

                <DialogActions sx={{ justifyContent: "end" }}>
                  <Button 
                    sx={{ 
                      width:"7vw",
                      height:"5vh",
                      color: "#124251", 
                      border: "1px solid #124251",
                      "&:hover": { 
                        opacity: "0.8",
                        border: "1px solid #0E363E" 
                      } 
                    }} 
                    onClick={() => resetForm()}
                  >
                    Cancelar
                  </Button>

                  <Button 
                    sx={{ 
                      width:"7vw",
                      height:"5vh",
                      color: "white", 
                      backgroundColor: "#124251",
                      "&:hover": { 
                        opacity: "0.8",
                        backgroundColor: "#0E363E"
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#B0B0B0", 
                        color: "#E0E0E0",
                      }
                    }}   
                    disabled={!newGraphName} 
                    onClick={() => {handleUpdateGraph(editGraphId)}}
                  >      
                    Salvar
                  </Button>
                </DialogActions>
              </Dialog>

              </Box>

              <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
             
              <TableCell align="left">Dashboard</TableCell>
              <TableCell align="right">Tipo</TableCell>
              <TableCell align="right">Variáveis</TableCell>
              <TableCell align="right">Dispositivo</TableCell>
              <TableCell align="right"></TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
          
  {dashboardList.map((dashboard : any) => {
    return <TableRow key={dashboard.number + dashboard.device_id} sx={{'&:hover': { backgroundColor: '#f5f5f5' },cursor: "pointer"}}>
      <TableCell component="th" scope="row">{dashboard.name}</TableCell>
      <TableCell align="right">{dashboard.variable_type === "monitoring" ? "Monitorada" : "Controle"}</TableCell>
      <TableCell align="right"></TableCell>
      <TableCell align="right"></TableCell>
      <TableCell align="right" sx={{ display: "flex", gap: "10px" }}>
        <Button
          size="small" 
          endIcon={<Edit />}  
          onClick={ () => {
            setEditGraph(true);
            getGraphInfo(dashboard.id);
          }} 
          sx={{color: "#124251",
          border: "1px solid #124251", 
          "&:hover": { backgroundColor: "#124251",border: "1px solid #124251",color: "white", opacity: "0.8" } 
          }}
        > 
            Editar
        </Button>      
        <Button 
          size="small"  
          onClick={ () => {
            setDeletedGraphId(dashboard.id);
            setDeletedGraphName(dashboard.name);
            setOpenConfirmDialogGraph(true);
          }} 
          sx={{
            color: "#124251",
            border: "1px solid #124251", 
            "&:hover": { backgroundColor: "red",border: "1px solid red",color: "white", opacity: "0.8" } 
          }} > 
          <Delete />
        </Button>
      </TableCell>
    </TableRow>
  })}
  <Dialog
    open={openConfirmDialogGraph}
    onClose={() => {
      setDeletedGraphId(null);
      setDeletedGraphName(null);
      setOpenConfirmDialogGraph(false);
    }}
  >
    <DialogTitle>Confirmar Exclusão</DialogTitle>
    <DialogContent>
      <Typography>
        Tem certeza de que deseja excluir {deletedGraphName} ? Esta ação é permanente e não pode ser desfeita.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button sx={{ color: "white" ,backgroundColor: "red", "&:hover": { backgroundColor: "red",opacity: "0.8" } }} onClick={() => {setOpenConfirmDialogGraph(false)}} color="primary">
        Cancelar
      </Button>
      <Button sx={{ color: "white" ,backgroundColor: "#124251", "&:hover": { backgroundColor: "#124251", opacity: "0.8" } }} onClick={() => {
        deleteDashboard(deletedGraphId);
        setOpenConfirmDialogGraph(false);
        setDeletedGraphId(null);
        setDeletedGraphName(null);
      }} color="secondary">
        Deletar
      </Button>
    </DialogActions>
  </Dialog>

          </TableBody>
        </Table>
      </TableContainer>
      
              


          </Box>
    </TabPanel>

  </TabContext>
    </Box>

    
  );
};
export default SetupGroup;
