import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useDispatch, useSelector, useStore } from "react-redux";
import { SizeMe } from "react-sizeme";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import CardBarra from "../../Components/dinamicos/CardBarra";
import CardGrafico from "../../Components/dinamicos/CardGrafico";
import CardPizza from "../../Components/dinamicos/CardPizza";
import CardVelocimetro from "../../Components/dinamicos/CardVelocimetro";
import { getDigitalTwin } from "../../store/slices/digitalTwinSlice";
import {
  getCharts,
  getLayouts,
  setLayouts,
} from "../../store/slices/monitoringSlice";

const MonitoringView = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const [resetKey, setResetKey] = useState(0);
  const storedCharts = useSelector(getCharts);
  const layout = getLayouts(store.getState());
  const digitalTwin = useSelector(getDigitalTwin);
  const ResponsiveGridLayout = WidthProvider(Responsive);

  const defaultLayout = [
    { i: "0", original_id: 151, x: 2, y: 0, w: 8, h: 1 },
    { i: "1", original_id: 168, x: 10, y: 0, w: 2, h: 1 },
    { i: "2", original_id: 167, x: 0, y: 0, w: 2, h: 1 },
    { i: "3", original_id: 158, x: 6, y: 1, w: 6, h: 1 },
    { i: "4", original_id: 155, x: 0, y: 1, w: 6, h: 1 },
    { i: "5", original_id: 171, x: 0, y: 2, w: 12, h: 1 },
    { i: "6", original_id: 174, x: 0, y: 2, w: 12, h: 1 },
    { i: "7", original_id: 173, x: 0, y: 2, w: 12, h: 1 },
  ];

  const handleLayoutChange = async (currentLayout) => {
    const newLayout = currentLayout.map((position, index) => {
      return {
        i: `${index}`,
        original_id: layout[index]?.original_id,
        x: position.x,
        y: position.y,
        w: position.w,
        h: position.h,
      };
    });
    dispatch(setLayouts(newLayout));

    for (let item of currentLayout) {
      const id = layout[item.i].original_id;
      try {
        await axios.put(`/graphs/${id}`, {
          position_metadata: JSON.stringify({
            x: item.x,
            y: item.y,
            w: item.w,
            h: item.h,
          }),
        });
      } catch (error) {
        console.error("Failed to update graph position metadata", error);
      }
    }
  };

  const resetLayout = async () => {
    dispatch(setLayouts(defaultLayout));
    setResetKey((prevKey) => prevKey + 1);

    for (let item of defaultLayout) {
      try {
        await axios.put(`/graphs/${item.original_id}`, {
          position_metadata: JSON.stringify({
            x: item.x,
            y: item.y,
            w: item.w,
            h: item.h,
          }),
        });
      } catch (error) {
        console.error("Failed to update graph position metadata", error);
      }
    }
  };

  return (
    <>
     <div
      style={{
        position: "absolute",
        right: "101px",
        top: "44px",
      }}
     >
     <button
        onClick={resetLayout}
        style={{
          color: "white",
          backgroundColor: "#0c5e5a",
          height: "25px",
          width: "122%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          
          // position: "absolute",
          // right: "100px",
          // top: "10px",
          // margin: "10px",
          borderRadius: "6px",
          fontWeight: 500,
          fontFamily: "Roboto",
          fontSize: "16px",
        }}
      >
        Reset
      </button>
     </div>

      <ResponsiveGridLayout
        key={resetKey}
        style={{
          overflowY: "auto",
          width: "calc(100% - 70px)",
        }}
        draggableHandle=".draggableHandle"
        className="layout"
        breakpoints={{
          xl: 1900,
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0,
        }}
        cols={{ xl: 12, lg: 12, md: 4, sm: 4, xs: 2, xxs: 2 }}
        rowHeight={250}
        layouts={{ xl: layout }}
        onLayoutChange={handleLayoutChange}
      >
        {storedCharts.map((chart, i) => {
          if (chart.default_visualization === "speedometer") {
            return (
              <div key={i}>
                <SizeMe monitorHeight>
                  {({ size }) => (
                    <div
                      style={{
                        height: "100%",
                      }}
                    >
                      <CardVelocimetro
                        elemento={chart}
                        w={size.width}
                        h={size.height}
                      />
                    </div>
                  )}
                </SizeMe>
              </div>
            );
          } else if (chart.default_visualization === "pie") {
            return (
              <div key={i}>
                <SizeMe monitorHeight>
                  {({ size }) => (
                    <div
                      style={{
                        height: "100%",
                      }}
                    >
                      <CardPizza
                        elemento={chart}
                        w={size.width}
                        h={size.height}
                      />
                    </div>
                  )}
                </SizeMe>
              </div>
            );
          } else if (chart.default_visualization === "bar") {
            return (
              <div key={i}>
                <SizeMe monitorHeight>
                  {({ size }) => (
                    <div
                      style={{
                        height: "100%",
                      }}
                    >
                      <CardBarra
                        elemento={chart}
                        w={size.width}
                        h={size.height}
                      />
                    </div>
                  )}
                </SizeMe>
              </div>
            );
          } else {
            return (
              <div key={i}>
                <SizeMe monitorHeight>
                  {({ size }) => (
                    <div
                      style={{
                        height: "100%",
                      }}
                    >
                      <CardGrafico
                        chart={chart}
                        width={size.width || undefined}
                        height={size.height || undefined}
                        refIndx={i}
                      />
                    </div>
                  )}
                </SizeMe>
              </div>
            );
          }
        })}
      </ResponsiveGridLayout>

      {storedCharts.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 200px)",
            gap: "10px",
          }}
        >
          <CircularProgress color={"inherit"} size={"25px"} /> Carregando ...{" "}
          {digitalTwin.name}
        </Box>
      )}
    </>
  );
};

export default MonitoringView;
