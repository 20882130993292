import {
  Paper,

  TextField,
  MenuItem,
  Autocomplete,
  
  InputLabel,
  Box,
  useTheme,
  Input,
  Select,
} from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import { useState, useEffect } from "react";
import { DateRangePicker } from "react-dates";
import { START_DATE, END_DATE } from "react-dates/constants";
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import '../../Views/RecommendationHistoryView/DateRangePickerStyles.css';
import Di2winDataService from "../../Services/NewDi2winDataService";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "../../Views/RecommendationHistoryView/DateRangePickerStyles.css";
import downloadCsvHelper from "../../Utils/downloadCsvHelper";
import { getDb } from "../../store/slices/dbSlice";
import { useSelector } from "react-redux";
interface ParametersProps {
  focusedInput: any;
  setFocusedInput: any;
  
  startDate1: any;
  setStartDate1: any;
  
  endDate1: any;
  setEndDate1: any;
  
  setStartTime: any;
  startTime: any;
  setEndTime: any;
  endTime: any;
  digitalTwin: any;
  setDigitalTwin: any;
  selectedSensor: any;
  setSelectedSensor: any;
  handleSubmit: any;
  isDarkTheme: any;
  formatInputDate: any;
  data: any;
}

const Parameters = (props: ParametersProps) => {
  const [dataToDownload, setDataToDownload] = useState<any>([]);
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);
  const db = useSelector(getDb);
  
  useEffect(() => {
    if (dataToDownload.length > 0) {
      setIsReadyToDownload(true);
    } else {
      setIsReadyToDownload(false);
    }
  }, [dataToDownload]);

 
  
  const handleDownloadCSV = () => {
    const localStartDate = props.startDate1.clone().startOf("day");
    const localEndDate = props.endDate1.clone().endOf("day");
    const range = { start: localStartDate, end: localEndDate };
    
    let auxDates = props.formatInputDate(props.startDate1, props.endDate1, props.startTime, props.endTime);

    const fetchedFrequencyDataPromises = [
      Di2winDataService
      .fetchAnalyticsData("1000", 6, [range]).then((res) => ({
        name: "Frquencia em Alarme Lida",
        sensor: 6,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res[0].data.freq.map((item, index) => [item, res[0].data.val[index]]),
        unit: props.selectedSensor.unit.abbreviation,
      })),

      Di2winDataService
      .fetchAnalyticsData("1000", 5, [range]).then((res) => ({
        name: "Frquencia em Alarme Recomendada",
        sensor: 5,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res[0].data.freq.map((item, index) => [item, res[0].data.val[index]]),
        unit: props.selectedSensor.unit.abbreviation,
      })),

      Di2winDataService
      .fetchAnalyticsData("1000", 8, [range]).then((res) => ({
        name: "Frquencia Total Lida",
        sensor: 8,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res[0].data.freq.map((item, index) => [item, res[0].data.val[index]]),
        unit: props.selectedSensor.unit.abbreviation,
      })),

      Di2winDataService
      .fetchAnalyticsData("1000", 7, [range]).then((res) => ({
        name: "Frquencia Total Lida Recomendada",
        sensor: 7,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res[0].data.freq.map((item, index) => [item, res[0].data.val[index]]),
        unit: props.selectedSensor.unit.abbreviation,
      })),
    ];

    const fetchedHistoricalDataPromise =[
      Di2winDataService
      .getRawInfo(
        db.dbName,
        db.collection,
        6,
        "1000",
        localStartDate,
        localEndDate)
      .then((res) => ({
        name: "CEGN em Alarme Lido",
        sensor: 6,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res,
        unit: props.selectedSensor.unit.abbreviation,
      })),

      Di2winDataService
      .getRawInfo(
        db.dbName,
        db.collection,
        5, 
        "1000", 
        localStartDate, 
        localEndDate)
      .then((res) => ({
        name: "CEGN em Alarme Recomendado",
        sensor: 5,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res,
        unit: props.selectedSensor.unit.abbreviation,
      })),

      Di2winDataService
      .getRawInfo(  
        db.dbName,
        db.collection,
        8, 
        "1000", 
        localStartDate, 
        localEndDate)
      .then((res) => ({
        name: "CEGN Total Lido",
        sensor: 8,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res,
        unit: props.selectedSensor.unit.abbreviation,
      })),
      
      Di2winDataService
      .getRawInfo(
        db.dbName,
        db.collection,
        7, 
        "1000", 
        localStartDate, 
        localEndDate)
      .then((res) => ({
        name: "CEGN Total Recomendado",
        sensor: 7,
        startDate: localStartDate,
        endDate: localEndDate,
        rawData: res,
        unit: props.selectedSensor.unit.abbreviation,
      })) 
    ];

    const fetchedAlarmDataPromise = [
      Di2winDataService
      .getAlarmData(props.data.digitalTwins[props.digitalTwin].id, auxDates[0], auxDates[1])
      .then((res) =>({
          name: "Tempo em Alarme",
          digitalTwinId: props.data.digitalTwins[props.digitalTwin].id,
          startDate: localStartDate,
          endDate: localEndDate,
          rawData: res,
          unit: props.selectedSensor.unit.abbreviation,
      }))
    ];

    const allPromises =  [...fetchedHistoricalDataPromise, ...fetchedFrequencyDataPromises, ...fetchedAlarmDataPromise];
    
    Promise.all(allPromises).then((fetchedData) => {
      setDataToDownload(fetchedData);
    }).catch((error) => {
      console.error('Error fetching data:', error);
    });
  };

  const isOutsideRange = (day) => {
    return day.isAfter();
  };

  const theme = useTheme();

  return (
   
      <Paper
        sx={{
         
          
          display: "flex-column",
          height: "100%",
          padding: "10px",
          justifyContent: "space-between",
          gap: "10px",
        
          
          
        }}
      >
        <InputLabel
          sx={{
            fontFamily: "Roboto",
            fontWeight: "400",
            fontSize: "larger",
           
            padding: "10px",
            // color: props.isDarkTheme ? "white" : "#124251",
            color: theme.palette.mode === "dark" ? "white" : "black",
          }}
        >
          Parâmetros:
        </InputLabel>

       
          <form onSubmit={props.handleSubmit} style={{ width: "100%" ,gap: "10px",display: "flex", flexDirection: "column", justifyContent: "space-between",height: "calc(100% - 80px)"}}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%",gap: "10px" }}>
              
               
                  
                  <InputLabel
                   
                  >
                    Período
                  </InputLabel>
                  <DateRangePicker
                      minimumNights={0}
                      noBorder={true}
                      startDate={props.startDate1}
                      startDateId={START_DATE}
                      endDate={props.endDate1}
                      endDateId={END_DATE}
                      onDatesChange={({ startDate, endDate }) => {
                        props.setStartDate1(startDate);
                        props.setEndDate1(endDate);
                      }}
                      focusedInput={props.focusedInput}
                      onFocusChange={(focusedInput) =>
                        props.setFocusedInput(focusedInput)
                      }
                      isOutsideRange={(day) => isOutsideRange(day)}
                      displayFormat={() => "DD/MM/YYYY"}
                      startDatePlaceholderText="Data Inicial"
                      endDatePlaceholderText="Data Final"
                      id="ySensors1"
                      name="ySensors1"
                    />
                
                <InputLabel
                   
                   >
                     Horário
                   </InputLabel>
                  <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" ,alignItems: "center" }}>
                  <Input
                    id="time"
                    name="time"
                    type="time"
                    onChange={(e) => {
                      props.setStartTime(e.target.value);
                    }}
                    sx={{
                     
                      padding: "10px",
                      backgroundColor: props.isDarkTheme ? "#323238" : "white",
                      color: !props.isDarkTheme ? "#323238" : "white",
                      width: "100%",
                    }}
                    required
                  />

                  <EastIcon sx={{ width: "30px", height: "30px" }} />
                  
                  <Input
                    id="time"
                    name="time"
                    type="time"
                    onChange={(e) => {
                      props.setEndTime(e.target.value);
                    }}
                    sx={{
                      padding: "10px",
                      backgroundColor: props.isDarkTheme ? "#323238" : "white",
                      color: !props.isDarkTheme ? "#323238" : "white",
                      width: "100%",
                    }}
                    required
                  />
                 </Box>
                
             
                 {/* <InputLabel
                   
                   >
                    Unidade Fabril
                   </InputLabel>
                  <Select
                   
                    sx={{
                      width: "100%",
                      color: !props.isDarkTheme ? "#323238" : "white",
                      backgroundColor: props.isDarkTheme ? "#323238" : "white",
                    }}
                  >
                    <MenuItem
                      value={"afogados"}
                      sx={{
                        width: "100%",
                        color: !props.isDarkTheme ? "#323238" : "white",
                        backgroundColor: props.isDarkTheme
                          ? "#323238"
                          : "white",
                      }}
                    >
                      Afogados
                    </MenuItem>
                  </Select> */}
                
                  <InputLabel
                   
                   >
                    Gêmeo Digital
                   </InputLabel>
                <Select
                  
                 
                  value={props.digitalTwin}
                  onChange={(e) => {
                    props.setDigitalTwin(parseInt(e.target.value));
                    props.setSelectedSensor(
                      props.data.digitalTwins[parseInt(e.target.value)]
                        .sensors[0]
                    );
                  }}
                  sx={{
                    width: "100%",
                    color: !props.isDarkTheme ? "#323238" : "white",
                    backgroundColor: props.isDarkTheme ? "#323238" : "white",
                  }}
                >
                  {props.data && props.data.digitalTwins && props.data.digitalTwins.map((dt: any, index: number) => (
                    <MenuItem
                      value={index}
                      sx={{
                        width: "100%",
                        color: !props.isDarkTheme ? "#323238" : "white",
                        backgroundColor: props.isDarkTheme
                          ? "#323238"
                          : "white",
                      }}
                    >
                      {dt.name}
                    </MenuItem>
                  ))}
                </Select>
             
                {props.digitalTwin !== null && (
                  <div>
                    {props.data.digitalTwins[props.digitalTwin].sensors.length >
                    0 ? (
                      <>
                       <InputLabel
                   
                   >
                    Sensor
                   </InputLabel>
                        <Autocomplete
                         
                          options={props.data.digitalTwins[
                            props.digitalTwin
                          ].sensors.filter(
                            (v: any) => v.variable_type === "kpi"
                          )}
                          getOptionLabel={(option: any) => option.name}
                          onChange={(event: any, newValue: any) => {
                            props.setSelectedSensor(newValue);
                          }}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                             
                              sx={{
                                width: "100%",
                                color: !props.isDarkTheme ? "#323238" : "white",
                                backgroundColor: props.isDarkTheme
                                  ? "#323238"
                                  : "white",
                              }}
                            />
                          )}
                          renderOption={(params, option) => (
                            <MenuItem
                              {...params}
                              sx={{
                                width: "100%",
                                color: !props.isDarkTheme ? "#323238" : "white",
                                backgroundColor: props.isDarkTheme
                                  ? "#323238"
                                  : "white",
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          )}
                        />
                      </>
                    ) : (
                      <p>nenhum sensor cadastrado</p>
                    )}
                  </div>
                )}

               
             
                
              
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" ,gap:'10px' ,marginTop:'10px'}}>
            {isReadyToDownload && (
                  <button
                    style={{
                      padding: "10px",
                      borderRadius: "6px",
                      flexGrow: "1",
                      
                      backgroundColor: "#124251",
                      color: "white",
                    }}
                    onClick={() => {
                      downloadCsvHelper(dataToDownload, true);
                    }}
                  >
                    Baixar CSV
                  </button>
                )}

                <button
                  className="submmitButton"
                  disabled={props.startTime === null || props.endTime === null}
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    flexGrow: "1",
                    
                    backgroundColor: "#124251",
                    color: "white",
                  }}
                  onClick={() => {
                    handleDownloadCSV();
                  }}
                >
                  Aplicar
                </button>
            </Box>
          </form>
       
      </Paper>
    
  );
};

export default Parameters;